import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { calculateReadingTime, countWordsFromHTML } from "utils";
import Skeleton from "components/Skeleton";

const BlogCard = ({ post, recent = false, category }) => {
  const { labels, title, id, published, content } = post;
  const elementRef = useRef();
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [wordCount, setWordCount] = useState(0);

  const TITLE_LENGTH = 120;

  useEffect(() => {
    const img = elementRef?.current?.querySelector("img");

    // temporary DOM element to parse HTML content
    const tempElement = document.createElement("div");
    tempElement.innerHTML = content;

    const desc = tempElement.textContent.trim();

    if (desc) {
      setDescription(desc);
    }

    if (img) {
      setImage(img?.src);
    }

    content && setWordCount(countWordsFromHTML(content));
  }, [post, content]);

  return (
    <a
      href={`/blog/${id}?category=${category}`}
      className="z-[10] rounded-md bg-transparent font-[Poppins]"
    >
      <div
        className={`flex ${
          recent ? "flex-row max-md:flex-col" : "flex-col"
        } bg-transparent rounded-lg gap-3 min-w-[250px]`}
      >
        {image ? (
          <img
            width={150}
            height={150}
            src={image}
            className={`w-full rounded h-[220px] object-cover ${
              recent
                ? "md:w-[300px] max-lg:w-[230px] max-lg:h-[200px] max-md:h-[220px] max-md:w-full"
                : ""
            }`}
            alt={title}
            key={"img_" + id}
          />
        ) : (
          <div
            className={`rounded-ms w-full h-[220px]  ${
              recent
                ? "md:w-[300px] max-lg:w-[230px] max-lg:h-[200px] max-md:h-[220px] max-md:w-full"
                : ""
            }`}
          >
            <div className="bg-[#ffffff] h-full flex flex-col gap-6">
              <div className="relative px-4 h-full rounded-lg overflow-hidden flex justify-start items-center">
                <div className="w-full h-full flex justify-end absolute items-center">
                  <div className="rounded-[30px] w-20 min-h-[6rem] h-[50%] -mr-6 bg-[#E5F0FE] -rotate-[30deg]" />
                </div>
                <div className="z-[5] bottom-0 absolute left-0 w-[40%] min-w-28 h-2/5 min-h-[100px] rounded-full blur-[80px]  bg-[#FCC682]" />

                {/* <div className="absolute -bottom-6 blur-[50px] -left-6 w-20 h-20 bg-gradient-4 rounded-full" /> */}
                <p className="text-base font-bold text-left text-neutral-30 z-[5]">
                  {title.slice(0, TITLE_LENGTH)}{" "}
                  {title.length > TITLE_LENGTH ? "..." : ""}
                </p>
              </div>
            </div>
          </div>
        )}
        <div
          className={`px-0 py-3 ${
            recent ? "md:py-0 py-3" : ""
          } flex flex-col gap-3`}
        >
          <div className="flex justify-start items-center gap-2 font-[Poppins]">
            <p className="text-[12px] text-[#667085]">
              {post.author.displayName}
            </p>
            <div className="bg-[#667085] rounded-full w-[4px] h-[4px]" />
            <p className="text-[12px] text-[#667085]">
              {moment(published).format("MMM D, YYYY")}
            </p>
          </div>
          <div className="flex items-start justify-between gap-2">
            <h3 className="text-[17px] text-[#1a1a1a] font-bold">{title}</h3>
          </div>
          <p
            className={` ${
              recent ? "text-[14px]" : "text-[14px]"
            } text-[#667085]`}
          >
            {`${description.substring(0, 90)}${
              description?.length > 1 ? "..." : ""
            }`}
          </p>
          <div
            dangerouslySetInnerHTML={{ __html: content }}
            ref={elementRef}
            className="hidden"
          />{" "}
          <div className="flex justify-start items-center gap-2">
            {wordCount ? (
              <p className="text-[12px] text-[#667085]">
                {calculateReadingTime(wordCount)} read
              </p>
            ) : null}
          </div>
          {labels?.length ? (
            <ul className="list-none flex gap-3 flex-wrap items-center justify-start">
              {labels.map((label, index) => (
                <div
                  className={`flex ${
                    (index + 1) % 2 === 0
                      ? "bg-[#FDF2FA] text-[#C11574]"
                      : "bg-[#F0F9FF] text-[#026AA2]"
                  } w-fit px-2.5 py-1 rounded-full font-medium items-center`}
                  key={index}
                >
                  <li
                    key={index}
                    className={`text-[14px] font-[Inter] text-primary-20 capitalize`}
                  >
                    {label}
                  </li>
                </div>
              ))}
            </ul>
          ) : null}
        </div>
      </div>
    </a>
  );
};

export const PostSkeleton = ({ recent }) => {
  return (
    <div className={`w-full pt-8 z-[100] ${recent ? "md:flex gap-4" : ""}`}>
      <Skeleton
        styles={`w-full flex-1 h-[250px] rounded-xl  ${
          recent ? "md:!min-w-[250px] md:!h-[200px]" : ""
        }`}
      />
      <div className="w-full mt-4 space-y-2 md:space-y-4">
        <div className=" flex gap-2 flex-col">
          <Skeleton styles="rounded-3xl w-full h-[33px]" />
          <Skeleton styles="rounded-3xl w-full h-[33px]" />
        </div>
        <div className="flex items-center justify-start gap-2">
          <Skeleton styles="rounded-3xl w-32 h-3" />
          <Skeleton styles="rounded-full w-2.5 h-2.5" />
          <Skeleton styles="rounded-3xl w-32 h-3" />
        </div>
        <div className="flex items-center justify-start gap-2">
          <Skeleton styles="rounded-3xl w-24 h-5" />
          <Skeleton styles="rounded-3xl w-24 h-5" />
        </div>
      </div>
    </div>
  );
};

export default BlogCard;
