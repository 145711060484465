import './App.css';
import { BrowserRouter, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import Home from 'pages/home';
import About from 'pages/about';
import CookieModal from 'components/CookieModal';
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import GetStarted from 'components/GetStarted';
import GetStartedProvider from 'context/GetStartedContextProvider';
import Blog from 'pages/blog';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from 'react';
import Post from 'pages/post';
import PrivacyPolicy from 'pages/privacypolicy';
import Terms from 'pages/terms';
import CookieNotice from 'pages/cookieNotice';

function App() {
  return (
    <BrowserRouter>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <GetStartedProvider>
        <I18nextProvider i18n={i18n}>
          <Routes>
            <Route path='/' element={<Wrapper />}>
              <Route index element={<Home />} />
              <Route path='/about-us' element={<About />} />
              <Route path='/blog' element={<Blog />} />
              <Route path='/blog/:id' element={<Post />} />
              <Route path='/privacy-policy' element={<PrivacyPolicy />} />
              <Route path='/terms' element={<Terms />} />
              <Route path='/cookie-notice' element={<CookieNotice />} />
            </Route>

          </Routes>

        </I18nextProvider>

        <CookieModal />
        <GetStarted />
      </GetStartedProvider>
    </BrowserRouter>
  );
}

const Wrapper = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);


  return <Outlet />;
}

export default App;
