import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as EnglishFlag } from "assets/english.svg";
import { ReactComponent as FrenchFlag } from "assets/french.svg";

const LanguageSwitcher = () => {
  const [isFrenchActive, setFrenchActive] = useState(false);
  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const savedLanguage = localStorage.getItem("selectedLanguage");

    if (savedLanguage) {
      setFrenchActive(savedLanguage === "fr");
      i18n.changeLanguage(savedLanguage);
    }
  }, [i18n]);

  const toggleLanguage = () => {
    setFrenchActive(!isFrenchActive);
    const newLanguage = isFrenchActive ? "en" : "fr";
    i18n.changeLanguage(newLanguage);
    localStorage.setItem("selectedLanguage", newLanguage);
  };

  return (
    <div className="relative z-40">
      <div className="group inline-block">
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="border border-[#B8BCCE]  rounded-full h-12 max-md:h-9 w-fit px-3 max-md:px-1.5 flex items-center justify-between gap-2.5 max-md:gap-1.5 outline-none focus:outline-none flex items-center"
        >
          <div className="flex items-center justify-start gap-1.5 max-md:gap-1">
            {isFrenchActive ? (
              <FrenchFlag className="max-md:w-4 max-md:h-4" />
            ) : (
              <EnglishFlag className="max-md:w-4 max-md:h-4" />
            )}
            <span
              className={`font-[PoppinsSemiBold] text-sm max-md:text-[14px]`}
            >
              {isFrenchActive ? "FR" : "EN"}
            </span>
          </div>

          <span className="">
            <svg
              className={`fill-current h-5 w-5 transform group-hover:-rotate-180 transition duration-150 ease-in-out`}
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M6.91895 10.3371L9.69134 13.196C9.86896 13.3792 10.1311 13.3792 10.3087 13.196L15.6668 7.67064C16.0011 7.32586 15.7984 6.66675 15.3581 6.66675H10.5893L6.91895 10.3371Z"
                fill="black"
              />
              <path
                opacity="0.5"
                d="M9.41083 6.66675H4.642C4.20165 6.66675 3.99899 7.32586 4.33333 7.67064L6.33882 9.73876L9.41083 6.66675Z"
                fill="black"
              />
            </svg>
          </span>
        </button>
        <div className="">
          <ul
            className={`font-[Poppins] transform scale-0 group-hover:scale-100 ${
              isOpen ? "" : ""
            } absolute transition duration-150 ease-in-out w-[180px] px-5 py-5 mt-2 origin-top rounded-[10px] bg-white font-[PoppinsSemibold] text-black space-y-4`}
          >
            <div className="flex items-center justify-start gap-2 border border-[#F4C5E6] bg-[#FFE5F7B2] rounded-[10px] px-4 py-4 z-40 min-w-20 pointer-events-none text-sm max-md:text-[14px]">
              {isFrenchActive ? (
                <FrenchFlag className="max-md:w-4 max-md:h-4" />
              ) : (
                <EnglishFlag className="max-md:w-4 max-md:h-4" />
              )}
              {isFrenchActive ? "French" : "English"}
            </div>
            <div
              className="flex items-center justify-start gap-2 border border-[#F1F1F1] rounded-[10px] bg-white px-4 py-4 z-40 min-w-20 cursor-pointer text-sm max-md:text-[14px]"
              onClick={toggleLanguage}
            >
              {isFrenchActive ? (
                <EnglishFlag className="max-md:w-4 max-md:h-4" />
              ) : (
                <FrenchFlag className="max-md:w-4 max-md:h-4" />
              )}
              {isFrenchActive ? "English" : "French"}
            </div>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default LanguageSwitcher;
