import Footer from "components/sections/home/HomeFooter";
import Navbar from "components/Navbar";
import PageHeader from "components/PageHeader";
import Brands from "components/sections/home/Brands";
import Careers from "components/sections/about/Careers";
import AboutUs from "components/sections/about/AboutUs";
import HeroSection from "components/sections/about/HeroSection";
import Team from "components/sections/about/Team";
import Map from "components/Map";

const About = () => {

  return (
    <main>
      <PageHeader title="About us | Smartcomply" />
      <div className="hero">
        <Navbar />
        <HeroSection />
      </div>
      {/* background: linear-gradient(8.3deg, #FFFFFF 9.89%, #E5F0FF 96.6%);
       */}
      <div
        className="bg-[linear-gradient(8.3deg,#FFFFFF_55.89%,#E5F0FF_100.6%)] max-md:bg-[linear-gradient(8.3deg,#FFFFFF_90.89%,#E5F0FF_100.6%)] bg-white"
      >
        <AboutUs />
        <Team />
        <Careers />
      </div>

      <Map />
      <Brands />
      <Footer />
    </main>
  );
};

export default About;
