const Skeleton = (props) => {
  const { styles } = props;

  return (
    <div
      className={`e-skeleton overflow-hidden rounded-full ${styles || ""}`}
    />
  );
};

export default Skeleton;
