import { ReactComponent as ShieldIcon } from "assets/shield3.svg";
import { ReactComponent as BadgeIcon } from "assets/award.svg";
import { useTranslation } from "react-i18next";
import briefcase from "assets/briefcase.svg";
import { useEffect, useState } from "react";
import Apply from "./Apply";

export default function Careers() {
  const { t } = useTranslation("about");
  const [openings, setOpenings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [activeJob, setActiveJob] = useState(null);

  const getAllOpenings = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://api.smartcomplyapp.com/api/user_mgt/open_position/`
      );

      setLoading(false);
      const data = await response.json();

      if (response.ok === true) {
        setOpenings(data.data);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllOpenings();
  }, []);

  return (
    <>
      <section
        id="careers"
        className=" px-14 py-24 max-md:px-8 max-[520px]:px-4 max-lg:py-16 bg-white"
      >
        <div className="mb-10 max-md:mb-4 flex items-start justify-start flex-col max-lg:max-w-[95%] max-[1200px]:max-w-[70%] max-w-[50%]">
          <h3 className="text-[#18214D] font-bold text-3xl max-md:text-[28px] text-left mb-4">
            {t("team.title2")}
          </h3>
        </div>

        <div className="grid grid-cols-3 items-start justify-between gap-6 max-md:grid-cols-2 max-[520px]:grid-cols-1">
          <div className="flex justify-start items-start gap-3">
            <ShieldIcon className="w-8 h-8 flex-shrink-0" />
            <div>
              <h4 className="text-[#000059] font-bold text-base mb-1">
                {t("team.reasons.title1")}
              </h4>
              <p className="text-[#5C6077] font-[Poppins] text-[14px]">
                {t("team.reasons.subtitle1")}
              </p>
            </div>
          </div>
          <div className="flex justify-start items-start gap-3">
            <ShieldIcon className="w-8 h-8 flex-shrink-0" />
            <div>
              <h4 className="text-[#000059] font-bold text-base mb-1">
                {t("team.reasons.title2")}
              </h4>
              <p className="text-[#5C6077] font-[Poppins] text-[14px]">
                {t("team.reasons.subtitle2")}
              </p>
            </div>
          </div>
          <div className="flex justify-start items-start gap-3">
            <BadgeIcon className="w-8 h-8 flex-shrink-0" />
            <div>
              <h4 className="text-[#000059] font-bold text-base mb-1">
                {t("team.reasons.title3")}
              </h4>
              <p className="text-[#5C6077] font-[Poppins] text-[14px]">
                {t("team.reasons.subtitle3")}
              </p>
            </div>
          </div>
        </div>

        <div className="px-12 py-14 max-md:px-6 max-md:py-7 bg-[#0F1535] rounded-xl border border-[#efefef] mt-16 max-md:mt-8">
          <h3 className="text-white font-[ManropeBold] font-[900] text-4xl max-md:text-2xl mt-6 mb-10 max-md:mt-3 max-md:mb-5">
            {t("openings.title")}
          </h3>
          {loading ? (
            <div className="loader w-[40px] h-[40px] mx-auto"></div>
          ) : openings.length ? (
            openings?.map((el, key) => (
              <div
                key={el.title + "-" + key}
                onClick={() => {
                  setActiveJob(el);
                  setOpen(true);
                }}
                className="cursor-pointer mb-4 p-8 max-[520px]:p-4 flex font-[Manrope] capitalize items-center justify-between max-md:flex-col max-md:justify-start max-md:items-start max-md:gap-6 max-[520px]:flex-wrap rounded-lg bg-white hover:bg-[#E0EDFF] border border-[#E5F0FF] hover:border-[#0052B4]"
              >
                <h4 className="font-[ManropeBold] text-base">{el.title}</h4>
                <div className="flex justify-end gap-10 max-sm:flex-col max-[520px]:justify-start max-[520px]:gap-3">
                  <div className="">
                    <p className="text-[#8B8B8B] text-[14px]">
                      {t("openings.location")}
                    </p>
                    <p className="font-[ManropeBold] text-[14px]">
                      {el.location}
                    </p>
                  </div>
                  <div className="">
                    <p className="text-[#8B8B8B] text-[14px]">
                      {t("openings.level")}
                    </p>
                    <p className="font-[ManropeBold] text-[14px]">{el.level}</p>
                  </div>
                  <div className="">
                    <p className="text-[#8B8B8B] text-[14px]">
                      {t("openings.department")}
                    </p>
                    <p className="font-[ManropeBold] text-[14px]">
                      {el.department}
                    </p>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="flex flex-col items-center justify-center mx-auto gap-4 max-w-[400px] max-md:my-8">
              <img
                src={briefcase}
                alt="briefcase"
                className="max-md:w-20 max-md:h-20"
              />
              <h5 className="text-white text-lg font-bold">
                No Open Positions
              </h5>
              <p className="text-white text-sm text-center">
                We&apos;re not hiring at the moment, but check back soon for
                exciting opportunities to join our team!
              </p>
            </div>
          )}
        </div>
      </section>
      <Apply open={open} job={activeJob} handleClose={() => setOpen(false)} />
    </>
  );
}
