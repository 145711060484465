import valerie from "assets/team/valerie.jpg";
import ope from "assets/team/ope.jpg";
import efe from "assets/team/efe.jpg";
import anita from "assets/team/anita.jpg";
import bola from "assets/team/bola.jpg";
import tayo from "assets/team/tayo.jpg";
import olusola from "assets/team/olusola.jpg";
import jude from "assets/team/jude.jpg";
import { Link } from "react-router-dom";
import { ReactComponent as LinkedIn } from "assets/linkedin-icon.svg";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { DownOutlined } from "@ant-design/icons";
import vf from "assets/advisors/vf.jpg";
import dayo from "assets/advisors/dayo.png";
import adetutu from "assets/advisors/adetutu.jpg";
import nurudeen from "assets/advisors/nurudeen.jpg";
import steve from "assets/advisors/steve.jpg";
import aderonke from "assets/advisors/ronke.jpg";
import david from "assets/advisors/david.png";
import sarah from "assets/advisors/sarah.jpg";

export default function Team() {
  const [showAdvisors, setShowAdvisors] = useState(true);
  const team = [
    {
      name: "Valerie Azubuike",
      role: "Chief Operations Officer",
      linkedin: "https://www.linkedin.com/in/valerie-azubuike-phri-/ ",
      image: valerie,
      key: "valerie",
    },
    {
      name: "Jude Ogbonna",
      role: "Country Head",
      linkedin: "https://www.linkedin.com/in/jude-ogbonna-493b2668/",
      image: jude,
      key: "jude",
    },
    {
      name: "Anita Ajalla",
      role: "VP, Engineering",
      linkedin: "https://www.linkedin.com/in/amara-ajalla/ ",
      image: anita,
      key: "anita",
    },
    {
      name: "Efe Ohwonigho",
      role: "Head of Customer Success and Partnership",
      linkedin: "https://www.linkedin.com/in/efe-ohwonigho-41a585a2/",
      image: efe,
      key: "efe",
    },
    {
      name: "Opeyemi Ajakaye-Maku",
      role: "Head of Compliance",
      linkedin: "https://www.linkedin.com/in/opeyemi-ajakaye-maku/",
      image: ope,
      key: "ope",
    },
    {
      name: "Adetayo Adetokun",
      role: "Head of Engineering",
      linkedin: "https://www.linkedin.com/in/adetayo-adetokun-9357801bb/",
      image: tayo,
      key: "tayo",
    },
    {
      name: "Olusola Oyekola",
      role: "Product Manager",
      linkedin: "https://www.linkedin.com/in/olusola-oyekola-251309152/",
      image: olusola,
      key: "olusola",
    },
    {
      name: "Bola Lateef Odeshina",
      role: "Platform Engineer",
      linkedin: "https://www.linkedin.com/in/bola-lateef-odeshina-b084b9268/ ",
      image: bola,
      key: "bola",
    },
  ];

  const advisors = [
    {
      name: "Dr. Victor Funmipe",
      role: "Founder and Chairman",
      linkedin: "https://www.linkedin.com/in/victor-f-phd-mba-07533436/",
      image: vf,
      key: "vf",
    },
    {
      name: "Dayo Koleowo",
      role: "Board Advisory Investment - Secure",
      linkedin: "https://www.linkedin.com/in/natekorl/?originalSubdomain=uk",
      image: dayo,
      key: "dayo",
    },
    {
      name: "Nurudeen Odeshina",
      role: "Board Advisory Cybersecurity - Secure",
      linkedin: "https://www.linkedin.com/in/nurudeenodeshina",
      image: nurudeen,
      key: "nurudeen",
    },
    {
      name: "Adetutu Ogunsowo",
      role: "Board Advisory Cybersecurity - Adhere",
      linkedin: "https://www.linkedin.com/in/adetutuogunsowo",
      image: adetutu,
      key: "adetutu",
    },
    {
      name: "Dr. Olufunso Steve Olofinlade",
      role: "Board Advisory Human Resources - Smartcomply",
      linkedin:
        "https://www.linkedin.com/in/olufunso-steve-olofinlade-cphr-shrm-gphr-7755701b/",
      image: steve,
      key: "steve",
    },
    {
      name: "Professor Thompson Aderonke Favour-Bethy",
      role: "Board Advisory Research and Development Academy - Academy",
      linkedin: "https://www.linkedin.com/in/aderonke-thompson",
      image: aderonke,
      key: "aderonke",
    },
    {
      name: "David Omole",
      role: "Board Advisory Operations - Secure",
      linkedin: "",
      image: david,
      key: "david",
    },
    {
      name: "Sarah Chidebelu-Eze",
      role: "Board Advisory Sales and Partnership - Smartcomply",
      linkedin: "https://www.linkedin.com/in/sarah-chidebelu-eze/",
      image: sarah,
      key: "sarah",
    },
  ];

  const { t } = useTranslation("about");

  return (
    <>
      <section className=" px-14 py-24 py-6 max-md:px-8 max-[520px]:px-4 max-lg:py-12 bg-white">
        <div
          id="advisors"
          className={`${showAdvisors ? "pb-24" : "pb-20"} max-lg:pb-12`}
        >
          <div
            className={`${
              showAdvisors ? "mb-10 max-md:mb-6" : "mb-0"
            } flex items-start justify-between w-full cursor-pointer gap-3`}
          >
            <h3 className="text-[#18214D] font-bold max-md:text-[28px] text-3xl text-left">
              {t(`team.advisors`)}
            </h3>

            <div
              onClick={() => setShowAdvisors(!showAdvisors)}
              className="flex items-center justify-center gap-1.5 px-3 py-1.5 rounded-md bg-[#E8E9EF] text-black text-[15px] font-[PoppinsMedium] capitalize"
            >
              {showAdvisors ? t("collapse") : t("expand")}
              <DownOutlined
                className={`transform ${
                  showAdvisors ? "-rotate-180" : ""
                } transition duration-150 ease-in-out text-[10px] text-[#1C274C]`}
              />
            </div>
          </div>

          {showAdvisors ? (
            <div className="grid grid-cols-4 max-[1200px]:grid-cols-3 max-[900px]:grid-cols-2 max-[500px]:grid-cols-1 h-auto gap-4">
              {advisors.map((member) => {
                const firstname = member.name.split(" ")[0];
                const nameHasTitle =
                  firstname.includes("Dr.") || firstname.includes("Professor");
                return (
                  <div
                    key={member.name}
                    className="relative rounded-xl overflow-hidden"
                  >
                    <img
                      src={member.image}
                      alt={member.name}
                      className="hover:scale-125 duration-[1000ms] ease-in-out h-full max-h-[460px] max-md:min-h-[400px] max-[520px]:min-h-[450px] w-full rounded-xl object-cover"
                    />
                    <div className="absolute bottom-4 h-fit flex w-full items-center justify-center">
                      <div className="flex flex-col justify-between items-start gap-3 bg-white w-[90%] rounded-xl p-4 max-lg:gap-2 h-[135px]">
                        <div>
                          <h5
                            className={`${
                              member.key === "aderonke" ||
                              member.key === "steve"
                                ? "leading-none max-[1200px]:leading-normal"
                                : ""
                            } text-[#18214D] text-[PoppinsSemibold] text-base`}
                          >
                            <span className="font-bold">
                              {nameHasTitle
                                ? member.name.split(" ")[0] +
                                  " " +
                                  member.name.split(" ")[1]
                                : firstname}{" "}
                            </span>
                            {member.name
                              .split(" ")
                              .slice(nameHasTitle ? 2 : 1)
                              .join(" ")}
                          </h5>
                          <p
                            className={`${
                              member.key === "aderonke" ||
                              member.key === "steve"
                                ? "max-[1700px]:leading-none text-[13.8px]"
                                : member.key !== "vf"
                                ? "max-[1700px]:leading-none text-sm"
                                : "text-sm"
                            } text-[#5C6077]`}
                          >
                            {t(`team.${member.key}`)}
                          </p>
                        </div>
                        {member.linkedin ? (
                          <Link
                            to={member.linkedin}
                            target="_blank"
                            className="flex gap-2 items-center justify-start"
                          >
                            <div className="bg-[#2563EB] rounded-full w-6 h-6 flex items-center justify-center box-shadow: 0px 2.5px5px_-0.63px_#0D0D1205]">
                              <LinkedIn className="w-3 h-3 rounded-full" />
                            </div>
                            <p className="text-sm text-[#5C6077]">LinkedIn</p>
                          </Link>
                        ) : null}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : null}
        </div>

        <div>
          <div className="mb-10 max-md:mb-6 flex items-start justify-start flex-col max-lg:max-w-[95%] max-[1200px]:max-w-[70%] max-w-[50%]">
            <h3 className="text-[#18214D] font-bold max-md:text-[28px] text-3xl text-left">
              {t(`team.title`)}
            </h3>
          </div>

          <div className="grid grid-cols-4 max-[1200px]:grid-cols-3 max-[900px]:grid-cols-2 max-[500px]:grid-cols-1 h-auto gap-4">
            {team.map((member) => (
              <div
                key={member.name}
                className="relative rounded-xl overflow-hidden"
              >
                <img
                  src={member.image}
                  alt={member.name}
                  className="hover:scale-125 duration-[1000ms] ease-in-out h-full max-h-[420px] max-md:min-h-[400px] max-[520px]:min-h-[450px] w-full rounded-xl object-cover"
                />
                <div className="absolute bottom-4 h-fit flex w-full items-center justify-center">
                  <div className="flex flex-col justify-between items-start gap-3 bg-white w-[90%] rounded-xl p-4 h-[120px]">
                    <div>
                      <h5 className="text-[#18214D] text-[PoppinsSemibold] text-base">
                        <span className="font-bold">
                          {member.name.split(" ")[0]}{" "}
                        </span>
                        {member.name.split(" ").slice(1).join(" ")}
                      </h5>
                      <p
                        className={`${
                          member.key === "efe" || member.key === "bola"
                            ? "max-[1700px]:leading-none text-sm"
                            : "text-sm"
                        } text-[#5C6077]`}
                      >
                        {t(`team.${member.key}`)}
                      </p>
                    </div>
                    {member.linkedin ? (
                      <Link
                        to={member.linkedin}
                        target="_blank"
                        className="flex gap-2 items-center justify-start"
                      >
                        <div className="bg-[#2563EB] rounded-full w-6 h-6 flex items-center justify-center box-shadow: 0px 2.5px5px_-0.63px_#0D0D1205]">
                          <LinkedIn className="w-3 h-3 rounded-full" />
                        </div>
                        <p className="text-sm text-[#5C6077]">LinkedIn</p>
                      </Link>
                    ) : null}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}
