import React, { useCallback, useEffect, useRef, useState } from "react";
import moment from "moment";
import { toast } from "react-toastify";
import { useParams, useSearchParams } from "react-router-dom";
import { calculateReadingTime } from "utils";
import Skeleton from "components/Skeleton";
import { countWordsFromHTML } from "utils";
import Navbar from "components/Navbar";
import PageHeader from "components/PageHeader";
import Footer from "components/sections/home/HomeFooter";
import { useTranslation } from "react-i18next";
import { PostSkeleton } from "components/sections/blog/BlogCard";
import BlogCard from "components/sections/blog/BlogCard";

const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
const BLOG_ID_SECURE = process.env.REACT_APP_GOOGLE_BLOG_ID_SECURE;
const BLOG_ID_ADHERE = process.env.REACT_APP_GOOGLE_BLOG_ID_ADHERE;
const BLOG_ID_INTEL = process.env.REACT_APP_GOOGLE_BLOG_ID_INTEL;

const Post = () => {
  const { t } = useTranslation("home");
  const elementRef = useRef();
  const [loading, setLoading] = useState(false);
  const [singlePost, setSinglePost] = useState(null);
  const [image, setImage] = useState("");
  const [wordCount, setWordCount] = useState(0);
  const { id } = useParams();

  const [posts, setPosts] = useState({ secure: [], adhere: [], intel: [] });

  const [postsloading, setPostsLoading] = useState(true);

  const [searchParams] = useSearchParams();
  const category = searchParams.get("category");

  const TITLE_LENGTH = 180;

  const BLOG_ID =
    category === "secure"
      ? BLOG_ID_SECURE
      : category === "adhere"
      ? BLOG_ID_ADHERE
      : category === "intel"
      ? BLOG_ID_INTEL
      : null;

  const getSinglePosts = useCallback(async () => {
    setLoading(true);

    if (BLOG_ID) {
      try {
        const response = await fetch(
          `https://www.googleapis.com/blogger/v3/blogs/${BLOG_ID}/posts/${id}?key=${API_KEY}`
        );

        const data = await response?.json();
        setSinglePost(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.error("Error getting post");
      }
    }
  }, [id, BLOG_ID]);

  useEffect(() => {
    getSinglePosts();
  }, [id, getSinglePosts]);

  useEffect(() => {
    const img = elementRef?.current?.querySelector("img");
    if (img) {
      setImage(img?.src);
    }

    // remove first image i.e article image
    const firstImage = document.querySelector(".singlePost img");
    // const separators = document.querySelector(".separator");
    if (firstImage) {
      firstImage.style.display = "none";
    }

    singlePost?.content && setWordCount(countWordsFromHTML(singlePost.content));
  }, [singlePost]);

  const getPosts = async () => {
    setPostsLoading(true);
    try {
      const response = await fetch(
        `https://www.googleapis.com/blogger/v3/blogs/${BLOG_ID}/posts?key=${API_KEY}`
      );

      setPostsLoading(false);
      const data = await response.json();

      if (response.status === 200) {
        if (!Array.isArray(data?.items)) return;
        const modifiedPosts = data?.items
          .map((post) => {
            const { replies, ...others } = post;
            return {
              ...others,
              labels: post?.labels?.map((label) => label?.toLowerCase()) || [],
              ...(category ? { category } : {}),
            };
          })
          .filter((el) => el.id !== id)
          .slice(0, 3);

        setPosts(modifiedPosts);
      }
    } catch (error) {
      setPostsLoading(false);
      toast.error(`${t("toast.posts")}`);
    }
  };

  useEffect(() => {
    getPosts();
  }, []);

  if (!category || !id || !singlePost) return;

  return (
    <main>
      <PageHeader title={`${singlePost.title} | Smartcomply`} />
      <div className="hero">
        <Navbar />
      </div>
      <>
        <header className="bg-[#fff] mt-[85px] max-md:mt-[70px] px-32 max-lg:px-[96px] max-md:px-8 max-[520px]:px-4 max-lg:pt-10 py-10 blog-hero">
          <div className="flex flex-col-reverse items-start gap-12 py-4">
            {loading || !singlePost ? (
              <>
                <Skeleton styles="w-full md:w-1/2 flex-1 h-[250px] rounded-xl" />
                <div className="w-full md:w-1/2 space-y-2 md:space-y-4">
                  <Skeleton styles="w-32 h-5 rounded-full" />

                  <div className=" flex gap-2 flex-col">
                    <Skeleton styles="rounded-3xl w-4/5 h-[33px]" />
                    <Skeleton styles="rounded-3xl w-4/5 h-[33px]" />
                  </div>
                  <div className="flex items-center justify-start gap-2">
                    <Skeleton styles="rounded-3xl w-32 h-3" />
                    <Skeleton styles="rounded-full w-2.5 h-2.5" />
                    <Skeleton styles="rounded-3xl w-32 h-3" />
                  </div>
                  <div className="flex items-center jutstify-start flex-wrap w-full gap-4">
                    <Skeleton styles="rounded-full w-[40px] h-[40px]" />
                    <Skeleton styles="rounded-full w-[40px] h-[40px]" />
                    <Skeleton styles="rounded-full w-[40px] h-[40px]" />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="w-full justify-center md:justify-end">
                  {image ? (
                    <img
                      className="rounded-xl w-full max-h-[500px] object-cover"
                      src={image}
                      alt={singlePost?.title}
                      priority
                      width={400}
                      height={400}
                    />
                  ) : (
                    <div className="border-l border-neutral-95 h-full">
                      <div className="bg-[#ffffff] rounded-2xl min-w-[350px] max-[480px]:min-w-[auto] max-[480px]:w-full h-full flex flex-col gap-6">
                        <div className="relative px-10 max-md:px-4 rounded-2xl overflow-hidden min-h-[250px] flex justify-center items-start">
                          <div className="absolute -bottom-6 blur-[50px] -left-6 w-20 h-20 bg-gradient-4 rounded-full" />
                          <p className="mt-20 text-lg font-bold text-left text-gray-700 z-[5]">
                            {singlePost?.title?.slice(0, TITLE_LENGTH)}{" "}
                            {singlePost?.title.length > TITLE_LENGTH
                              ? "..."
                              : ""}
                          </p>{" "}
                          <div className="w-full h-full flex justify-end absolute items-center">
                            <div className="rounded-[50px] w-36 min-h-[9rem] h-[60%] -mr-6 bg-[#E5F0FE] -rotate-[30deg]" />
                          </div>
                          <div className="z-[5] bottom-0 absolute left-0 w-[40%] min-w-28 h-2/5 min-h-[100px] rounded-full blur-[80px]  bg-[#FCC682]" />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className={`px-0 py-3  flex flex-col gap-3`}>
                  <div className="flex justify-start items-center gap-2 font-[Poppins]">
                    <p className="text-sm text-[#667085]">
                      {singlePost.author.displayName}
                    </p>
                    <div className="bg-[#667085] rounded-full w-[4px] h-[4px]" />
                    <p className="text-sm text-[#667085]">
                      {moment(singlePost.published).format("MMM D, YYYY")}
                    </p>
                  </div>
                  <div className="flex items-start justify-between gap-2">
                    <h3 className="text-[2rem] text-[#1a1a1a] font-bold">
                      {singlePost.title}
                    </h3>
                  </div>

                  <div className="flex justify-start items-center gap-2">
                    {wordCount ? (
                      <p className="text-sm text-[#667085]">
                        {calculateReadingTime(wordCount)} read
                      </p>
                    ) : null}
                  </div>
                  {singlePost.labels?.length ? (
                    <ul className="list-none flex gap-3 flex-wrap items-center justify-start">
                      {singlePost.labels.map((label, index) => (
                        <div
                          className={`flex ${
                            (index + 1) % 2 === 0
                              ? "bg-[#FDF2FA] text-[#C11574]"
                              : "bg-[#F9F5FF] text-[#6941C6]"
                          } w-fit px-2.5 py-1 rounded-full font-medium items-center`}
                          key={index}
                        >
                          <li
                            key={index}
                            className={`text-[15px] font-[Inter] text-primary-20 capitalize`}
                          >
                            {label}
                          </li>
                        </div>
                      ))}
                    </ul>
                  ) : null}
                </div>
              </>
            )}
          </div>
        </header>
        <div className="bg-white px-32 max-lg:px-[96px] max-md:px-8 max-[520px]:px-4 max-lg:pt-10 py-8">
          <section className="">
            {loading ? (
              <div className="loader w-8 h-8" />
            ) : (
              <div className="prose lg:prose-xl customFont">
                <div
                  className="break-all prose lg:prose-xl singlePost"
                  dangerouslySetInnerHTML={{ __html: singlePost?.content }}
                  ref={elementRef}
                />
              </div>
            )}
          </section>
        </div>
        {posts?.length ? (
          <div className="bg-white py-24 px-32 max-lg:px-[96px] max-md:px-8 max-[520px]:px-4">
            <h4 className="font-bold font-[Inter] text-[#1A1A1A] text-left text-xl capitalize mb-8">
              {t(`blog.similarPosts`)}
            </h4>
            <div className="w-full mt-12 max-md:mt-8">
              {postsloading ? (
                <div className="grid grid-cols-3 max-[920px]:grid-cols-2 max-[570px]:grid-cols-1 gap-6 max-[900px]:gap-5">
                  {Array(3)
                    .fill(0)
                    .map((_arg, index) => (
                      <PostSkeleton key={"skeleton" + index} />
                    ))}
                </div>
              ) : (
                <>
                  {posts?.length ? (
                    <div className="grid grid-cols-3 max-[920px]:grid-cols-2 max-[570px]:grid-cols-1 gap-6 max-[900px]:gap-5">
                      {posts.map((card, index) => (
                        <BlogCard
                          key={index}
                          post={card}
                          category={card.category}
                        />
                      ))}
                    </div>
                  ) : (
                    <h4 className="flex justify-center items-center capitalize font-extrabold text-black text-xl md:text-2xl">
                      {t("blog.noPosts")}
                    </h4>
                  )}
                </>
              )}
            </div>
          </div>
        ) : null}
      </>
      <Footer />
    </main>
  );
};

export default Post;
