import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ReactComponent as LineWhiteIcon } from "assets/line-white.svg";
import { ReactComponent as CloseCircle } from "assets/close-circle.svg";
import { useGetStartedContext } from "context/GetStartedContextProvider";
import SCTrust from "assets/sc-trust.svg";
import SCAcademy from "assets/sc-academy.svg";
import SCSecure from "assets/sc-secure.svg";
import SCIntel from "assets/sc-intel.svg";
import SCAdhere from "assets/sc-adhere-new.svg";

const MobileNavbar = ({ pathname }) => {
  const { t } = useTranslation("home");
  const { handleModalChange } = useGetStartedContext();

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [productsOpen, setProductsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    setProductsOpen(false);
  }, [isSidebarOpen]);

  return (
    <div>
      <button
        onClick={toggleSidebar}
        className={`lg:hidden fixed z-50 top-6 right-4 text-black`}
      >
        <svg
          className={`h-6 w-6 ${
            isSidebarOpen || pathname !== "/" ? "text-black" : "text-gray-50"
          }`}
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M13.3333 4.66675L2.66659 4.66675"
            stroke="#121212"
            stroke-width="1.5"
            stroke-linecap="round"
          />
          <path
            opacity="0.5"
            d="M13.3333 8L2.66659 8"
            stroke="#121212"
            stroke-width="1.5"
            stroke-linecap="round"
          />
          <path
            d="M13.3333 11.3333L2.66659 11.3333"
            stroke="#121212"
            stroke-width="1.5"
            stroke-linecap="round"
          />
        </svg>
      </button>

      <div
        className={`lg:hidden fixed bg-white  ${
          isSidebarOpen ? "inset-y-[70px]" : "inset-y-0"
        } left-0 w-full z-40 transform transition-transform duration-300 ease-in-out h-fit ${
          isSidebarOpen ? "translate-y-0" : "-translate-y-full"
        }`}
      >
        <div
          className={`flex p-6 pt-8 flex-col bg-white  items-start justify-start font-[Manrope] gap-10 max-lg:gap-5 text-black text-[14px] overflow-y-auto ${
            productsOpen ? "h-[calc(100vh_-_70px)]" : "h-fit"
          } relative`}
        >
          <CloseCircle
            className="absolute top-6 right-6 cursor-pointer"
            onClick={() => setIsSidebarOpen(false)}
          />
          <Link to="/" className="font-[ManropeBold] text-base capitalize">
            {t("navlinks.home")}
          </Link>

          <div className="">
            <button
              onClick={() => setProductsOpen(!productsOpen)}
              className="outline-none focus:outline-none flex items-end"
            >
              <span className="font-[ManropeBold] text-base capitalize">
                {t("navlinks.products.title")}
              </span>
              <span className="">
                <svg
                  className="fill-current h-6 w-6 transform group-hover:-rotate-180 transition duration-150 ease-in-out"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M6.91895 10.3371L9.69134 13.196C9.86896 13.3792 10.1311 13.3792 10.3087 13.196L15.6668 7.67064C16.0011 7.32586 15.7984 6.66675 15.3581 6.66675H10.5893L6.91895 10.3371Z"
                    fill="black"
                  />
                  <path
                    opacity="0.5"
                    d="M9.41083 6.66675H4.642C4.20165 6.66675 3.99899 7.32586 4.33333 7.67064L6.33882 9.73876L9.41083 6.66675Z"
                    fill="black"
                  />
                </svg>
              </span>
            </button>
            {productsOpen ? (
              <div className="">
                <ul className="pt-4 transform transition duration-150 space-y-2 ease-in-out origin-top">
                  <div className="rounded-lg h-full w-[300px] max-[400px]:w-full space-y-3 bg-white relative">
                    <div className="p-4 border border-[#F1F1F1] hover:border-[#F4C5E6] hover:bg-[#FFE5F7B2] rounded-lg">
                      <Link
                        target="_blank"
                        to="https://secure.smartcomply.com"
                        className="flex flex-row items-start justify-start space-x-3"
                      >
                        <img
                          src={SCSecure}
                          className="w-7 h-7 flex-shrink-0"
                          alt="secure"
                          eager
                        />
                        <div>
                          <h4 className="font-[Manrope] font-[600] text-[15px] text-black mb-2">
                            Smartcomply Secure
                          </h4>
                          <p className="font-[Manrope] text-[#6F6F6F] text-[14px]">
                            {t("navlinks.products.secure")}
                          </p>
                        </div>
                      </Link>
                    </div>
                    <div className="p-4 border border-[#F1F1F1] hover:border-[#F4C5E6] hover:bg-[#FFE5F7B2] rounded-lg">
                      <Link
                        target="_blank"
                        to="https://adhere.smartcomply.com/"
                        className="flex flex-row items-start justify-start space-x-3"
                      >
                        <img
                          src={SCAdhere}
                          className="w-7 h-7 flex-shrink-0"
                          alt="adhere"
                          eager
                        />
                        <div>
                          <h4 className="font-[Manrope] font-[600] text-[15px] text-black mb-2">
                            Adhere by Smartcomply
                          </h4>
                          <p className="font-[Manrope] text-[#6F6F6F] text-[14px]">
                            {t("navlinks.products.adhere")}
                          </p>
                        </div>
                      </Link>
                    </div>

                    <div className="p-4 border border-[#F1F1F1] hover:border-[#F4C5E6] hover:bg-[#FFE5F7B2] rounded-lg">
                      <Link
                        target="_blank"
                        to="https://intel.smartcomply.com/"
                        className="flex flex-row items-start justify-start space-x-3"
                      >
                        <img
                          src={SCIntel}
                          className="w-7 h-7 flex-shrink-0"
                          alt="intel"
                          eager
                        />
                        <div>
                          <h4 className="font-[Manrope] font-[600] text-[15px] text-black mb-2">
                            SmartcomplyIntel
                          </h4>
                          <p className="font-[Manrope] text-[#6F6F6F] text-[14px]">
                            {t("navlinks.products.intel")}
                          </p>
                        </div>
                      </Link>
                    </div>

                    <div className="p-4 border border-[#F1F1F1] hover:border-[#F4C5E6] hover:bg-[#FFE5F7B2] rounded-lg">
                      <Link
                        target="_blank"
                        to="https://academy.smartcomply.com/"
                        className="flex flex-row items-start justify-start space-x-3"
                      >
                        <img
                          src={SCAcademy}
                          className="w-7 h-7 flex-shrink-0"
                          alt="academy"
                          eager
                        />
                        <div>
                          <h4 className="font-[Manrope] font-[600] text-[15px] text-black mb-2">
                            Smartcomply Academy
                          </h4>
                          <p className="font-[Manrope] text-[#6F6F6F] text-[14px]">
                            {t("navlinks.products.academy")}
                          </p>
                        </div>
                      </Link>
                    </div>
                    <div className="p-4 border border-[#F1F1F1] hover:border-[#F4C5E6] hover:bg-[#FFE5F7B2] rounded-lg">
                      <p className="flex flex-row items-start justify-start space-x-3">
                        <img
                          src={SCTrust}
                          className="w-7 h-7 flex-shrink-0"
                          alt="trust"
                          eager
                        />
                        <div>
                          <h4 className="font-[Manrope] font-[600] text-[15px] text-black mb-2">
                            Smartcomply Trust
                          </h4>
                          <p className="font-[ManropeMedium] text-[#EF5025] uppercase text-[ManropeMedium] text-[14px]">
                            {t("navlinks.comingSoon")}!!!
                          </p>
                        </div>
                      </p>
                    </div>
                  </div>
                </ul>
              </div>
            ) : null}
          </div>

          <Link
            to="/about-us"
            className="font-[ManropeBold] text-base capitalize"
          >
            {t("navlinks.about")}
          </Link>

          <Link to="/blog" className="font-[ManropeBold] text-base capitalize">
            {t("blog.title")}
          </Link>

          <div
            onClick={() => {
              setProductsOpen(false);
              setIsSidebarOpen(false);
              handleModalChange(true);
            }}
            className="cursor-pointer flex text-[14px] text-[#ffffff]  font-[PoppinsMedium] items-center justify-center mx-auto px-6 py-4 w-full gap-2 rounded-sm bg-[#1B2761]"
          >
            <p className="">{t("button.getStarted")}</p>
            <LineWhiteIcon />
            <p className="">{t("button.now")}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileNavbar;
