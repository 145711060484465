import { ReactComponent as Line } from "assets/line-small.svg";
import { Link } from "react-router-dom";
import shield from "assets/shield2.png";
import defense from "assets/defense.png";
import academy_cert from "assets/academy-cert.png";
import intel from "assets/intel.png";
import adhere from "assets/adhere.png";
import { useTranslation } from "react-i18next";
import SCTrust from "assets/sc-trust.svg";
import SCAcademy from "assets/sc-academy.svg";
import SCSecure from "assets/sc-secure.svg";
import SCIntel from "assets/sc-intel.svg";
import SCAdhere from "assets/sc-adhere.svg";

const Products = () => {
  const { t } = useTranslation("home");

  return (
    <>
      <section className=" px-14 py-24 max-md:px-8 max-[520px]:px-4 max-lg:py-14 max-md:py-8 bg-white">
        <div className="mb-14 flex items-center justify-center flex-col max-lg:max-w-[100%] max-[1200px]:max-w-[70%] max-w-[50%] mx-auto">
          <h3 className="text-[#0D0D12] font-bold max-md:text-[28px] max-[520px]:w-full text-4xl md:text-5xl text-center mb-4">
            {t("section3.title")}
          </h3>
          <p className="text-[#666D80] font-[Manrope] text-center text-sm">
            {t("section3.subtitle")}
          </p>
        </div>

        <section className="w-full flex flex-col gap-0 max-md:gap-10 justify-center items-center">
          <div className="w-full flex max-md:flex-col max-md:gap-0 max-md:mt-0 gap-6 max-lg:gap-3 -mt-40 max-[1200px]:-mt-32 max-lg:-mt-14 z-[10] justify-center">
            <div className="w-[40%] max-md:w-full max-md:min-w-[auto] min-w-[300px] space-y-3 rounded-ss-2xl rounded-se-2xl p-10 max-md:px-6 pb-20 max-md:pb-12 bg-[linear-gradient(8.3deg,#FFFFFF_9.89%,#E5F0FF_96.6%)]">
              <img src={SCSecure} className="mb-8" alt="secure" />

              <h4 className="text-[#0B1029] text-lg font-[Manrope] font-[600]">
                {t("products.secure.name")}
              </h4>
              <p className="text-[#424242] font-[Manrope] text-sm">
                {t("products.secure.subtitle")}
              </p>
              <div className="flex gap-3 text-sm items-center justify-start pt-6">
                <Link
                  className="text-[#1B2761] text-[15px] font-[PoppinsSemibold]"
                  to="https://secure.smartcomply.com/"
                  target="_blank"
                >
                  {t("learnMore")}{" "}
                </Link>
                <Line className="" />
              </div>
            </div>
            <div className="max-md:max-h-[350px]">
              <img
                className="h-full object-cover rounded-2xl object-top max-md:w-full max-md:max-h-[350px]"
                src={defense}
                alt="smartcomply secure"
              />
            </div>
          </div>

          <div className="w-full flex max-md:flex-col max-md:gap-0 max-md:mt-0 gap-6 max-lg:gap-3 -mt-40 max-[1200px]:-mt-24 max-lg:-mt-6 z-[25] justify-center max-h-[450px] max-lg:max-h-[360px] max-md:max-h-[none]">
            <div className="w-[40%] max-md:w-full max-md:min-w-[auto] min-w-[300px] space-y-3 rounded-ss-2xl rounded-se-2xl p-10 max-md:px-6 pb-20 max-md:pb-12 bg-[linear-gradient(0deg,#FFFFFF_9.4%,#E3FDF9_100%)]">
              <img src={SCAdhere} className="mb-8" alt="adhere" />
              <h4 className="text-[#0B1029] text-lg font-[Manrope] font-[600]">
                {t("products.adhere.name")}
              </h4>
              <p className="text-[#424242] font-[Manrope] text-sm">
                {t("products.adhere.subtitle")}
              </p>
              <div className="flex gap-3 text-sm items-center justify-start pt-6">
                <Link
                  className="text-[#1B2761] text-[15px] font-[PoppinsSemibold]"
                  to="https://adhere.smartcomply.com/"
                  target="_blank"
                >
                  {t("learnMore")}{" "}
                </Link>
                <Line className="" />
              </div>
            </div>
            <div className="max-h-[450px] max-lg:max-h-[360px] max-md:max-h-[350px]">
              <img
                className="h-full object-cover object-top w-full rounded-2xl max-md:max-h-[350px]"
                src={adhere}
                alt="adhere by smartcomply"
              />
            </div>
          </div>

          <div className="w-full flex max-md:flex-col max-md:gap-0 max-md:mt-0 gap-6 max-lg:gap-3  -mt-40 max-[1200px]:-mt-32 max-lg:-mt-14  z-[20] justify-center">
            <div className="w-[40%] max-md:w-full max-md:min-w-[auto] min-w-[300px] space-y-3 rounded-ss-2xl rounded-se-2xl p-10 max-md:px-6 pb-20 max-md:pb-12 bg-[linear-gradient(0.24deg,#FFFFFF_0.21%,#E8E9EF_99.79%)]">
              <img src={SCIntel} className="mb-8" alt="intel" />
              <h4 className="text-[#0B1029] text-lg font-[Manrope] font-[600]">
                {t("products.intel.name")}
              </h4>
              <p className="text-[#424242] font-[Manrope] text-sm">
                {t("products.intel.subtitle")}
              </p>
              <div className="flex gap-3 text-sm items-center justify-start pt-6">
                <Link
                  className="text-[#1B2761] text-[15px] font-[PoppinsSemibold]"
                  to="https://intel.smartcomply.com/"
                  target="_blank"
                >
                  {t("learnMore")}{" "}
                </Link>
                <Line className="" />
              </div>
            </div>
            <div className="max-md:max-h-[350px]">
              <img
                className="h-full object-cover rounded-2xl object-top max-md:w-full max-md:max-h-[350px]"
                src={intel}
                alt="smartcomplyintel"
              />
            </div>
          </div>
          <div className="w-full flex max-md:flex-col max-md:gap-0 max-md:mt-0 gap-6 max-lg:gap-3  -mt-40 max-[1200px]:-mt-32 max-lg:-mt-14  z-[15] justify-center">
            <div className="w-[40%] max-md:w-full max-md:min-w-[auto] min-w-[300px] space-y-3 rounded-ss-2xl rounded-se-2xl p-10 max-md:px-6 pb-20 max-md:pb-12 bg-[linear-gradient(25.89deg,#FFFFFF_27.48%,#F1FFD6_83.66%)]">
              <img src={SCAcademy} className="mb-8" alt="academy" />
              <h4 className="text-[#0B1029] text-lg font-[Manrope] font-[600]">
                {t("products.academy.name")}
              </h4>
              <p className="text-[#424242] font-[Manrope] text-sm">
                {t("products.academy.subtitle")}
              </p>
              <div className="flex gap-3 text-sm items-center justify-start pt-6">
                <Link
                  target="_blank"
                  className="text-[#1B2761] text-[15px] font-[PoppinsSemibold]"
                  to="https://academy.smartcomply.com/"
                >
                  {t("learnMore")}{" "}
                </Link>
                <Line className="" />
              </div>
            </div>
            <div className="max-md:max-h-[350px]">
              <img
                className="h-full object-cover rounded-2xl object-top max-md:w-full max-md:max-h-[350px]"
                src={academy_cert}
                alt="smartcomply academy"
              />
            </div>
          </div>
          <div className="w-full flex max-md:flex-col max-md:gap-0 gap-6 max-lg:gap-3 z-[0] justify-center">
            <div className="w-[40%] max-md:w-full max-md:min-w-[auto] min-w-[300px] space-y-3 rounded-ss-2xl rounded-se-2xl p-10 max-md:px-6 pb-20 max-md:pb-12 bg-[linear-gradient(196.92deg,#FFE5F7_11.66%,#FFFFFF_84.18%)]">
              <img src={SCTrust} className="mb-8" alt="trust" />

              <h4 className="text-[#0B1029] text-lg font-[Manrope] font-[600]">
                {t("products.trust.name")}
              </h4>
              <p className="text-[#424242] font-[Manrope] text-sm">
                {t("products.trust.subtitle")}
              </p>
              <div className="flex gap-3 text-sm items-center justify-start pt-6">
                <p className="text-[#1B2761] text-[15px] font-[PoppinsSemibold] uppercase">
                  {t("navlinks.comingSoon")}!!!
                </p>
                {/* <Line className="" /> */}
              </div>
            </div>
            <div className=" max-md:max-h-[350px]">
              <img
                className="h-full object-cover rounded-2xl object-top max-md:w-full max-md:max-h-[350px]"
                src={shield}
                alt="smartcomply trust"
              />
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default Products;
