import { ArrowRightOutlined } from "@ant-design/icons";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const HeroSection = () => {
  const { t } = useTranslation("home");

  const [submitting, setSubmitting] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [touched, setTouched] = useState(false);

  const resetForm = () => {
    setEmail("");
    setError("");
    setSubmitting(false);
    setTouched(false);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const handleSubcription = async (values) => {
    setSubmitting(true);
    const response = await fetch(
      "https://api.smartcomplyapp.com/api/user_mgt/subscribers/",
      {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify(values),
      }
    );
    const data = await response.json();
    setSubmitting(false);

    if (response?.ok) {
      toast.success(`${values?.email} ${t("toast.successSubscription")}`);
      resetForm();
    } else {
      setSubmitting(false);
      toast.error(data?.message || `${t("toast.errorSubscription")}`);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSubcription({ email });
  };

  return (
    <div className="flex flex-col w-full">
      <div className="mt-[85px] min-h-[450px] max-md:mt-[70px] px-14 pt-14 pb-4 max-md:px-8 max-[520px]:px-4 max-lg:pt-10 relative bg-cover bg-center w-full min-h-[400px] max-md:min-h-[400px] z-[5] relative bg-[linear-gradient(8.3deg,#FFFFFF_7.89%,#E5F0FF_85.6%)]">
        <div className="flex flex-col space-y-8 md:space-y-0 md:flex-row md:justify-between md:space-x-10 py-4 gap-4">
          <div className="w-full space-y-8 md:space-y-6 z-[30]">
            <div>
              <h2 className="font-bold text-[#0c111d] text-[50px]  max-w-[80%] max-lg:text-[40px] max-md:text-[33px] max-md:max-w-[280px] leading-tight text-[#0B1029] max-md:text-center max-md:mx-auto">
                {t("blog.header.title")}
              </h2>
            </div>

            <div className="">
              <p className="text-sm text-[PoppinsMedium] max-md:text-center text-black">
                {t("blog.header.subtitle")}
              </p>
              <form
                onSubmit={handleSubmit}
                className="pt-3 w-[500px] max-md:mx-auto max-sm:w-[90%] max-[450px]:w-full"
              >
                <div className=" w-full flex items-center justify-start gap-3 border-[#1B2761] border-[1.5px] rounded-md p-3">
                  <input
                    className="h-10 pl-3 bg-transparent border-0 rounded-md flex-1 max-[450px]:w-full placeholder:text-[#768796] text-[#768796] text-sm focus:outline-none focus:bg-transparent font-[Manrope]"
                    type="text"
                    name="email"
                    id="email"
                    value={email}
                    onChange={(e) => {
                      !touched && setTouched(true);
                      setEmail(e.target.value);

                      !validateEmail(e.target.value) && e.target.value
                        ? setError("Please enter a valid email")
                        : setError("");
                    }}
                    placeholder={t('blog.header.emailPlaceholder')}
                  />
                  <button
                    type="submit"
                    disabled={submitting || (!email && touched) || error}
                    className="bg-[#1B2761] h-10 capitalize px-6 max-[400px]:px-3 text-white flex items-center justify-center gap-2 text-[14px] rounded-md disabled:opacity-80 font-[Poppins]"
                  >
                    {submitting ? (
                      <div className="loader w-[25px] h-[25px]"></div>
                    ) : (
                      <>
                        {t('blog.header.emailButton')}
                        <ArrowRightOutlined className="text-white text-[13px]" />
                      </>
                    )}
                  </button>
                </div>
                {error && email ? (
                  <div className="text-[#F85359] text-[14px] pt-1">{error}</div>
                ) : null}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default HeroSection;
