import { createContext, useContext, useState } from "react";

export const GetStartedContext = createContext(null);

const GetStartedProvider = ({ children }) => {
  const [show, setShow] = useState(false);

  const handleModalChange = (status) => setShow(status);

  return (
    <GetStartedContext.Provider value={{ show, handleModalChange }}>
      {children}
    </GetStartedContext.Provider>
  );
};

export const useGetStartedContext = () => {
  const context = useContext(GetStartedContext);

  if (!context) {
    throw new Error("Please use GetStarted Provider in parent compoenent");
  }

  return context;
};

export default GetStartedProvider;
