import { AnimatePresence } from "framer-motion";
import { useState } from "react";
import AnimateCenter from "./Animations/AnimationCenter";
import { ArrowRightOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { useForm } from "react-hook-form";
import { object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useGetStartedContext } from "context/GetStartedContextProvider";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

export default function GetStarted() {
  const { show, handleModalChange } = useGetStartedContext();
  const { t } = useTranslation("home");
  const [submitting, setSubmitting] = useState(false);

  const inputClasses =
    "h-[2.8rem] w-full border border-[#E9E9E9] bg-white rounded-md text-[#121212] placeholder:text-[#121212] mt-1 pl-6 outline:none focus:outline-none";

  const validationSchema = object().shape({
    firstname: string().required("Please enter first name"),
    lastname: string().required("Please enter last name"),
    business: string().required("Please enter business name"),
    phone: string().required("Please enter phone number"),
    code: string(),
    email: string()
      .email("Please enter a valid email")
      .required("Please enter an email"),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(validationSchema) });

  const closeModal = () => {
    handleModalChange(false);
    reset();
    setSubmitting(false);
  };

  const submitForm = async (values) => {
    setSubmitting(true);
    const response = await fetch(
      "https://api.smartcomplyapp.com/api/user_mgt/getstarted/",
      {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify(values),
      }
    );
    const data = await response.json();
    setSubmitting(false);

    if (response?.ok) {
      toast.success(`${values?.email} ${t("toast.successSubscription")}`);
      reset();
      setSubmitting(false);
      closeModal();
    } else {
      toast.error(data?.message || `${t("toast.errorSubscription")}`);
    }
  };

  const onSubmit = (values) => {
    console.log(values);
    const { business, ...others } = values;
    submitForm({ ...others, "business-name": business });
  };

  return (
    <>
      <AnimatePresence>
        {show ? (
          <AnimateCenter
            exit={{ opacity: 0 }}
            className="fixed flex items-center justify-center h-full z-[1500] top-0 left-0 w-full bg-[#1D293999] overflow-y-auto"
          >
            <div className="rounded-[10px] w-[550px] self-start max-md:w-[90%] h-fit bg-white my-6">
              <div className="flex justify-between items-center gap-5 bg-[#E8E9EF] px-8 py-6 rounded-ss-[10px] rounded-se-[10px]">
                <h3 className="text-[#0D0D12] font-bold text-2xl">
                  {t(`button.getStarted`)}
                </h3>
                <CloseCircleOutlined
                  className="text-2xl cursor-pointer text-[#0D0D12]"
                  onClick={closeModal}
                />
              </div>
              <form
                className=" px-8 py-6 space-y-3 font-[Poppins]"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="">
                  <label className="text-[#696969] text-[13px]">
                    First Name
                  </label>
                  <input
                    placeholder=""
                    className={inputClasses}
                    {...register("firstname")}
                  />
                  {errors.firstname ? (
                    <p className="text-[red] text-[13px] mt-1">
                      {errors.firstname.message}
                    </p>
                  ) : null}
                </div>
                <div className="">
                  <label className="text-[#696969] text-[13px]">
                    Last Name
                  </label>
                  <input
                    placeholder=""
                    className={inputClasses}
                    {...register("lastname")}
                  />
                  {errors.lastname ? (
                    <p className="text-[red] text-[13px] mt-1">
                      {errors.lastname.message}
                    </p>
                  ) : null}
                </div>
                <div className="">
                  <label className="text-[#696969] text-[13px]">
                    Business Name
                  </label>
                  <input
                    placeholder=""
                    className={inputClasses}
                    {...register("business")}
                  />
                  {errors.business ? (
                    <p className="text-[red] text-[13px] mt-1">
                      {errors.business.message}
                    </p>
                  ) : null}
                </div>
                <div className="">
                  <label className="text-[#696969] text-[13px]">
                    Phone Number
                  </label>
                  <input
                    placeholder=""
                    className={inputClasses}
                    {...register("phone")}
                  />
                  {errors.phone ? (
                    <p className="text-[red] text-[13px] mt-1">
                      {errors.phone.message}
                    </p>
                  ) : null}
                </div>
                <div className="">
                  <label className="text-[#696969] text-[13px]">
                    Email address
                  </label>
                  <input
                    placeholder=""
                    className={inputClasses}
                    {...register("email")}
                  />
                  {errors.email ? (
                    <p className="text-[red] text-[13px] mt-1">
                      {errors.email.message}
                    </p>
                  ) : null}
                </div>
                <div className="h-6" />
                <button
                  type="submit"
                  className="text-white w-full gap-5 rounded-md flex items-center h-[2.9rem] bg-[#1B2761] justify-center disabled:opacity-80"
                  disabled={submitting}
                >
                  <p>{t(`button.getStarted`)}</p>
                  {submitting ? (
                    <div className="loader w-[25px] h-[25px]"></div>
                  ) : (
                    <ArrowRightOutlined className="text-white" />
                  )}
                </button>
              </form>
            </div>
          </AnimateCenter>
        ) : null}
      </AnimatePresence>
    </>
  );
}
