import { ReactComponent as LineWhiteIcon } from "assets/line-white.svg";
import AnimateBottom from "components/Animations/AnimateBottom";
import { useTranslation } from "react-i18next";

// logos
import redtech from "assets/new-logos/redtech-white.png";
import abeg from "assets/new-logos/abeg-white.png";
import unitybank from "assets/new-logos/unity-bank.png";
import tellerone from "assets/new-logos/tellerone-white.png";
import nova from "assets/new-logos/nova-bank-white.png";
import piggyvest from "assets/new-logos/piggyvest-white.png";
import suntrust from "assets/new-logos/suntrust-bank.png";
import pivo from "assets/new-logos/pivo-white.png";
import brbcapital from "assets/new-logos/brb-capital.png";
import lenco from "assets/new-logos/lenco-white.png";

import { useGetStartedContext } from "context/GetStartedContextProvider";

const HeroSection = () => {
  const { t } = useTranslation("about");
  const { handleModalChange } = useGetStartedContext();

  return (
    <div className="flex flex-col w-full">
      <div
        id="hero-section"
        className="mt-[85px] min-h-[450px] max-md:mt-[70px] px-14 pt-14 pb-4 max-md:px-8 max-[520px]:px-4 max-lg:pt-10 relative about-hero bg-cover bg-center w-full min-h-[520px] max-md:min-h-[400px] z-[5] relative"
      >
        <div className="w-full h-full bg-[linear-gradient(90deg,#0B1029_0%,rgba(11,16,41,0)_100%)] absolute top-0 left-0" />
        <div className="flex flex-col space-y-8 md:space-y-0 md:flex-row md:justify-between md:space-x-10 py-4 gap-4">
          <div className="w-full space-y-8 md:space-y-6 z-[30]">
            <div>
              <h2 className="font-bold text-white text-[50px]  max-w-[400px] max-lg:text-[40px] max-md:text-[33px] max-md:max-w-[280px] leading-tight text-[#0B1029] max-md:text-center max-md:mx-auto">
                {t("hero.title")}
              </h2>
            </div>

            <p className="text-white font-[Manrope] md:w-[48%] max-md:text-center max-md:mx-auto">
              {t("hero.subtitle")}
            </p>
            <div className="flex justify-start items-center max-md:flex-wrap max-md:justify-center">
              <div
                onClick={() => {
                  handleModalChange(true);
                }}
                className="flex h-fit w-max items-center text-[14px] text-[#ffffff] font-[PoppinsMedium] justify-center px-6 py-4 w-fit gap-2 rounded-md bg-[#1B2761] cursor-pointer"
              >
                <p className="">{t("button.demo")}</p>
                <LineWhiteIcon className="flex-shrink-0" />
                <p className="">{t("button.now")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="px-14 pb-0 max-md:pb-0 max-md:px-0 -mt-20 max-md:mt-0 z-[10]">
        <AnimateBottom delay={0.4} className="border-0">
          <div className="bg-[#0B1029] overflow-hidden w-full rounded-none md:rounded-lg flex flex-col justify-center items-start px-12 py-12 max-md:px-8">
            <p className="uppercase text-left text-sm text-[#979FC9] tracking-[0.1em] mb-8 max-md:mb-5 max-md:text-center">
              {t("hero.trustedText")}
            </p>
            <div className="w-full flex gap-8 items-center justify-between logos">
              <img className="max-w-[70px]" src={redtech} alt="redtech" />

              <img className="max-w-[70px]" src={abeg} alt="abeg" />

              <img className="max-w-[70px]" src={unitybank} alt="unitybank" />

              <img className="max-w-[70px]" src={tellerone} alt="tellerone" />

              <img className="max-w-[70px]" src={nova} alt="nova" />

              <img className="max-w-[70px]" src={piggyvest} alt="piggyvest" />

              <img
                className="max-w-[70px]"
                src={suntrust}
                alt="subtrust bank"
              />

              <img className="max-w-[70px]" src={pivo} alt="pivo" />

              <img
                className="max-w-[70px]"
                src={brbcapital}
                alt="brb capital"
              />

              <img className="max-w-[70px]" src={lenco} alt="lenco" />
            </div>
          </div>
        </AnimateBottom>
      </div>
    </div>
  );
};
export default HeroSection;
