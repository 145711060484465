import React from "react";
import { motion } from "framer-motion";

export default function AnimateBottom({ children, delay, ...props }) {
  return (
    <motion.div
      initial={{ y: 20, opacity: 0 }}
      whileInView={{ y: 0, opacity: 1 }}
      transition={{
        duration: 0.5,
        delay: delay || 0,
      }}
      viewport={{ once: true }}
      {...props}
    >
      {children}
    </motion.div>
  );
}
