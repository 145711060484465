import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import homeEn from "./utils/locales/en/home.json";
import homeFr from "./utils/locales/fr/home.json";
import aboutEn from "./utils/locales/en/about.json";
import aboutFr from "./utils/locales/fr/about.json";

const resources = {
	en: {
		home: homeEn,
		about: aboutEn,
	},
	fr: {
		home: homeFr,
		about: aboutFr,
	},
};

i18n.use(initReactI18next).init({
	lng: "en", // default
	fallbackLng: "en",
	interpolation: {
		escapeValue: false,
	},
	resources: resources,
});

export default i18n;
