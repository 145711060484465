import { ReactComponent as XIcon } from "assets/x.svg";
import { ReactComponent as FacebookIcon } from "assets/facebook.svg";
import { Link } from "react-router-dom";
import smartcomply_logo from "assets/smartcomply-white.svg";
import { ReactComponent as LinkedinIcon } from "assets/linkedin.svg";
import { ReactComponent as MSECEBadge } from "assets/msece.svg";
import { ReactComponent as NigeriaBadge } from "assets/nigeria-badge.svg";
import { ReactComponent as LineIcon } from "assets/line.svg";
import { ReactComponent as YoutubeIcon } from "assets/youtube.svg";
import { ReactComponent as InstagramIcon } from "assets/instagram.svg";
import { ReactComponent as LineWhiteIcon } from "assets/line-white.svg";
import { useTranslation } from "react-i18next";
import { ReactComponent as ArrowHeadIcon } from "assets/arrow-head.svg";
import { useGetStartedContext } from "context/GetStartedContextProvider";

const Footer = () => {
  const { t } = useTranslation("home");
  const { handleModalChange } = useGetStartedContext();

  return (
    <footer className="bg-[#0D0D12] px-14 pt-14 pb-10 max-md:px-8 max-[520px]:px-4 max-lg:py-10">
      <div className="flex flex-col gap-10 items-center justify-center relative">
        <div className="flex flex-wrap items-center justify-between max-md:justify-center gap-8 w-full border-b border-[#272835] pb-10">
          <h2 className="font-bold text-[50px] max-md:text-[35px] text-[#F6F8FA] max-md:text-center max-[520px]:text-[1.6rem]">
            {t("footer.title")}
          </h2>
          <div className="flex items-center justify-end gap-6 max-md:gap-3 max-md:justify-center max-[450px]:flex-col max-[450px]:w-full">
            <div
              onClick={() => {
                handleModalChange(true);
              }}
              className="cursor-pointer flex items-center text-[14px] text-[#ffffff] font-[PoppinsMedium] justify-center mx-auto px-6 py-4 w-fit gap-2 rounded-sm bg-[#1B2761] max-[450px]:w-full"
            >
              <p className="">{t("button.demo")}</p>
              <LineWhiteIcon />
              <p className="">{t("button.now")}</p>
            </div>
            <Link
              to="mailto:info@smartcomply.com"
              className="flex items-center text-[14px] text-[#ffffff] font-[PoppinsMedium] justify-center mx-auto px-6 py-4 w-fit gap-2 rounded-sm bg-[#1A1B25] max-[450px]:w-full"
            >
              <p className="">{t("button.contactUs")}</p>
            </Link>
          </div>
        </div>
        <div className="w-full flex items-start justify-between gap-12 flex-wrap max-md:flex-col border-b border-[#272835] pb-10">
          <div className="max-w-80 flex flex-col justify-start items-start gap-5">
            <img
              src={smartcomply_logo}
              alt="smartcomply"
              className="w-36 max-md:w-48 max-[500px]:w-32"
            />
            <p className="text-left text-sm text-[#666D80] w-full">
              {t("footer.subtitle")}
            </p>
            <div className="mt-3">
              <p className="text-left text-sm text-[#666D80] w-full pb-3">
                {t("footer.followUs")}:
              </p>
              <div className="flex gap-2 items-center justify-start">
                <Link
                  to="https://www.linkedin.com/company/smartcomply-ltd"
                  target="_blank"
                  className="bg-[#272835] shadow-[0px_4px_8px_-1px_#0D0D1205] rounded-full w-8 h-8 flex items-center justify-center"
                >
                  <LinkedinIcon className="" />
                </Link>
                <Link
                  to="https://x.com/smartcomplyapp"
                  target="_blank"
                  className="bg-[#272835] shadow-[0px_4px_8px_-1px_#0D0D1205] rounded-full w-8 h-8 flex items-center justify-center"
                >
                  <XIcon className="" />
                </Link>
                <Link
                  to="https://www.youtube.com/@smartcomply"
                  target="_blank"
                  className="bg-[#272835] shadow-[0px_4px_8px_-1px_#0D0D1205] rounded-full w-8 h-8 flex items-center justify-center"
                >
                  <YoutubeIcon className="" />
                </Link>
                <Link
                  to="https://www.instagram.com/smartcomply/"
                  target="_blank"
                  className="bg-[#272835] shadow-[0px_4px_8px_-1px_#0D0D1205] rounded-full w-8 h-8 flex items-center justify-center"
                >
                  <InstagramIcon className="" />
                </Link>
                <Link
                  to="https://www.facebook.com/smartcomply"
                  target="_blank"
                  className="bg-[#272835] shadow-[0px_4px_8px_-1px_#0D0D1205] rounded-full w-8 h-8 flex items-center justify-center"
                >
                  <FacebookIcon className="" />
                </Link>
              </div>
            </div>
          </div>
          <div className="flex items-start justify-between gap-12 max-md:gap-8 flex-wrap max-sm:flex-col text-[#F6F8FA]">
            <ul className="flex flex-col gap-2">
              <li className="font-bold text-sm text-[#666D80] mb-1.5 capitalize">
                {t("navbar.products")}
              </li>

              <li className="text-sm">
                <Link target="_blank" to="https://secure.smartcomply.com">
                  Smartcomply Secure
                </Link>
              </li>
              <li className="text-sm">
                <Link target="_blank" to="https://adhere.smartcomply.com/">
                  Adhere by Smartcomply
                </Link>
              </li>

              <li className="text-sm">
                <Link target="_blank" to="https://intel.smartcomply.com/">
                  SmartcomplyIntel
                </Link>
              </li>
              <li className="text-sm">
                <Link target="_blank" to="https://academy.smartcomply.com/">
                  Smartcomply Academy
                </Link>
              </li>
              {/* <li className="text-sm">
                <Link target="_blank" to="https://secure.smartcomply.com/">
                  Smartcomply Trust
                </Link>
              </li> */}
            </ul>
            <ul className="flex flex-col gap-2">
              <li className="font-bold capitalize text-sm text-[#666D80] mb-1.5">
                {t("footer.company")}
              </li>
              <li className="text-sm capitalize">
                <Link to="/about-us">{t("footer.links.about")}</Link>
              </li>
              <li className="text-sm capitalize">
                <a href="/about-us#careers">{t("footer.links.careers")}</a>
              </li>
              <li className="text-sm capitalize">
                <a href="/blog">{t("footer.links.blog")}</a>
              </li>
            </ul>
            <ul className="flex flex-col gap-2">
              <li className="font-bold capitalize text-sm text-[#666D80] mb-1.5">
                {t("footer.support")}
              </li>
              {/* <li className="text-sm">FAQ</li> */}
              <li className="text-sm">
                <Link to="mailto:info@smartcomply.com">
                  {t("button.contactUs")}
                </Link>
              </li>
            </ul>
          </div>
          <div className="flex justify-start items-center gap-6">
            <NigeriaBadge className="" />
            <MSECEBadge className="" />
          </div>
        </div>

        <div className="flex flex-col justify-start items-start gap-12 w-full  border-b border-[#272835] pb-10">
          <div className="w-full flex items-center justify-between gap-12 max-md:flex-col max-md:items-start text-sm">
            <ul className="flex flex-col gap-4 text-[#F6F8FA] max-w-60">
              <li className="font-bold text-sm text-[#666D80] capitalize">
                {/* {t("footer.address")} */}
                Physical Address
              </li>
              <li className="text-sm">
                The Bunker Building 279 Herbert Macaulay Way Yaba Lagos,
                Nigeria.
              </li>
              <li className="text-sm capitalize">
                {t("footer.email")}:{" "}
                <Link href="mailto:info@smartcomply.com" className="lowercase">
                  info@smartcomply.com
                </Link>
              </li>
            </ul>

            <div className="flex gap-4 items-center max-lg:flex-wrap text-[#F6F8FA] max-md:gap-2 text-sm max-md:text-[14px]">
              <p className="">Nigeria</p>
              <LineIcon className="hidden md:inline" />
              <ArrowHeadIcon className="inline md:hidden" />
              <p className="">USA</p>
              <LineIcon className="hidden md:inline" />
              <ArrowHeadIcon className="inline md:hidden" />
              <p className="">Canada</p>
              <LineIcon className="hidden md:inline" />
              <ArrowHeadIcon className="inline md:hidden" />
              <p className="">Ghana</p>
              <LineIcon className="hidden md:inline" />
              <ArrowHeadIcon className="inline md:hidden" />
              <p className="">Kenya</p>
              <LineIcon className="hidden md:inline" />
              <ArrowHeadIcon className="inline md:hidden" />
              <p className="">Ivory Coast</p>
            </div>
          </div>
        </div>

        <ul className="flex justify-center items-center gap-12 max-md:gap-6 max-[420px]:flex-col w-full text-[#666D80] text-sm">
          <li>
            <Link to="/terms">{t("footer.footerLinks.terms")}</Link>
          </li>
          <li>
            <Link to="/privacy-policy">
              {t("footer.footerLinks.privacyPolicy")}
            </Link>
          </li>
          <li>
            <Link to="/cookie-notice">
              {t("footer.footerLinks.cookieNotice")}
            </Link>
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
