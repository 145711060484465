import Navbar from "components/Navbar";
import Brands from "components/sections/home/Brands";
import Footer from "components/sections/home/HomeFooter";
import PageHeader from "components/PageHeader";
import Map from "components/Map";
import HeroSection from "components/sections/blog/HeroSection";
import AllPosts from "components/sections/blog/AllPosts";

const Blog = () => {

  return (
    <main>
      <PageHeader title="Blog | Smartcomply" />
      <div className="hero">
        <Navbar />
        <HeroSection />
      </div>
      <AllPosts />

      <Map />
      <Brands />
      <Footer />
    </main>
  );
};

export default Blog;
