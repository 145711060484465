import { AnimatePresence } from "framer-motion";
import { useCallback, useState } from "react";
import { ArrowRightOutlined, CloseOutlined } from "@ant-design/icons";
import { useForm } from "react-hook-form";
import { object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import AnimateCenter from "components/Animations/AnimationCenter";
import { useDropzone } from "react-dropzone";
import { ReactComponent as Cloud } from "assets/cloud-upload.svg";
import { ReactComponent as CloseCircle } from "assets/close-circle.svg";
import axios from "axios";

export default function Apply({ open, handleClose, job }) {
  const { t } = useTranslation("about");
  const { t: th } = useTranslation("home");

  const [submitting, setSubmitting] = useState(false);
  const [acceptedFiles, setAcceptedFiles] = useState([]);
  const [error, setError] = useState("");
  const inputClasses =
    "h-[5.5rem] w-full border border-[#E9E9E9] bg-white rounded-md text-[#949494] text-[14px] placeholder:text-[#949494] mt-1 pl-3 pt-3 outline:none focus:outline-none";

  const validationSchema = object().shape({
    letter: string().required("Please enter cover letter"),
    file: string().required("Please submit file"),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm({ resolver: yupResolver(validationSchema) });

  const closeModal = () => {
    handleClose(false);
    reset();
    setSubmitting(false);
    setAcceptedFiles([]);
  };

  const submitForm = async (values) => {
    setSubmitting(true);
    const response = await axios.post(
      "https://api.smartcomplyapp.com/api/user_mgt/job_application/",
      values
    );

    setSubmitting(false);

    if (response?.data?.status === "success") {
      toast.success(`Application submitted successfully`);
      reset();
      setSubmitting(false);
      closeModal();
    } else {
      toast.error(response?.message || `${th("toast.errorSubscription")}`);
    }
  };

  const onSubmit = (values) => {
    setSubmitting(true);

    const formData = new FormData();
    formData.append("cover_letter", values.letter);
    formData.append("open_position", job.id);
    formData.append("resume", acceptedFiles[0]);

    submitForm(formData);
  };

  function convertBytes(size, unit = "kb") {
    if (unit === "kb") {
      return Number((size / 1024).toFixed()) + "KB";
    } else if (unit === "mb") {
      return Number((size / (1024 * 1024)).toFixed()) + "MB";
    } else {
      return Number(size.toFixed()) + "bytes";
    }
  }

  // dropzone
  const acceptedFileTypes = {
    "application/pdf": [".pdf"],
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
      ".docx",
    ],
    "application/msword": [".doc"],
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        setError("");

        setValue("file", acceptedFiles[0].name);
        setAcceptedFiles(acceptedFiles);
      }
    },
    [setAcceptedFiles, setSubmitting]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    onDropAccepted() {
      setError("");
    },
    onDropRejected(fileRejections, event) {
      if (fileRejections.length > 1) {
        setError("Max number of files is 1");
      } else if (fileRejections.some((file) => file.file.size > 1048576 * 10)) {
        setError("Max file size is 10MB");
      } else {
        setError("Valid files include PDF, DOC(X)");
      }
    },
    maxFiles: 1,
    multiple: false,
    accept: acceptedFileTypes,
    maxSize: 1048576 * 10,
  });

  const handleDelete = (filePath) => {
    setAcceptedFiles((prevFiles) =>
      prevFiles.filter((file) => file.path !== filePath)
    );
    setValue("file", "");
  };

  const files = acceptedFiles?.map((file) => (
    <>
      <li
        key={file.path}
        className="m-0 mb-4 flex items-start justify-between gap-3"
      >
        <div className="flex flex-1 gap-3 ">
          <div className="flex flex-1 gap-3 justify-start items-center max-sm:flex-wrap max-sm:gap-2">
            <p className="break-all text-left text-[13px] font-normal text-[#0C111D]">
              {file?.name}
            </p>
            <div className="bg-[#98A2B3] w-1 h-1 flex-shrink-0 rounded-full" />
            <p className="text-left text-[13px] font-normal text-[#0C111D]">
              {convertBytes(file.size, "mb")}
            </p>
          </div>
        </div>

        <div className="flex gap-2">
          <CloseOutlined
            className="cursor-pointer text-[#98A2B3] text-md"
            onClick={() => handleDelete(file.path)}
          />
        </div>
      </li>
    </>
  ));

  return (
    <>
      <AnimatePresence>
        {open ? (
          <AnimateCenter
            exit={{ opacity: 0 }}
            className="fixed flex items-center justify-center h-full z-[1500] top-0 left-0 w-full bg-[#1D293999] overflow-y-auto"
          >
            <div className="rounded-[10px] w-[550px] self-start max-md:w-[90%] h-fit bg-white my-auto">
              <div className="flex justify-between items-center gap-5 bg-[#E8E9EF] px-8 py-6 rounded-ss-[10px] rounded-se-[10px]">
                <h3 className="text-[#0D0D12] font-bold text-2xl">
                  {t(`openings.applyTitle`)}
                </h3>
                <CloseCircle
                  className="text-2xl cursor-pointer flex-shrink-0 w-7 h-7"
                  onClick={closeModal}
                />
              </div>
              <form
                className=" px-8 py-6 space-y-3 font-[Poppins]"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="">
                  <label className="text-[#696969] text-[13px]">
                    {t("openings.coverLetterLabel")}
                  </label>
                  <textarea
                    placeholder={t("openings.coverLetterPlaceholder")}
                    className={inputClasses}
                    {...register("letter")}
                  />
                  {errors.letter ? (
                    <p className="text-[red] text-[13px] mt-1">
                      {errors.letter.message}
                    </p>
                  ) : null}
                </div>

                <div className="">
                  <div
                    {...getRootProps({ className: "dropzone" })}
                    className="flex w-full items-center justify-start gap-20 max-sm:gap-6 max-sm:flex-wrap rounded-sm border border-dashed border-[#D0D5DD] px-4 py-4"
                  >
                    <input {...getInputProps()} />
                    <div className="flex gap-3 items-center justify-start font-[Poppins]">
                      <Cloud className="flex-shrink-0" />
                      <div>
                        <p className="text-left text-[13px] font-[PoppinsMedium] text-[#0C111D]">
                          {t("openings.uploadResume")}
                        </p>
                        <p className="text-left text-xs text-[#98A2B3]">
                          PDF, Word | 10MB max.
                        </p>
                      </div>
                    </div>

                    <button
                      type="button"
                      className="mt-2 rounded-sm bg-[#EEEAFE] p-2 py-1"
                    >
                      <p className="text-center text-xs text-[#783BF9]">
                        {t("openings.upload")}
                      </p>
                    </button>
                  </div>
                  {errors.file || error ? (
                    <p className="text-[red] text-[13px] mt-1">
                      {errors.file.message || error}
                    </p>
                  ) : null}
                </div>

                {acceptedFiles?.length > 0 && (
                  <aside className="mt-3">
                    <ul>{files}</ul>
                  </aside>
                )}

                <div className="h-6" />
                <button
                  type="submit"
                  className="text-white w-full gap-5 rounded-md flex items-center h-[2.9rem] bg-[#1B2761] justify-center disabled:opacity-80"
                  disabled={submitting}
                >
                  <p>{t(`openings.applyButton`)}</p>
                  {submitting ? (
                    <div className="loader w-[25px] h-[25px]"></div>
                  ) : (
                    <ArrowRightOutlined className="text-white" />
                  )}
                </button>
              </form>
            </div>
          </AnimateCenter>
        ) : null}
      </AnimatePresence>
    </>
  );
}
