import Navbar from "components/Navbar";
import HeroSection from "components/sections/home/HeroSection";
import Brands from "components/sections/home/Brands";
import Footer from "components/sections/home/HomeFooter";
import PageHeader from "components/PageHeader";
import Products from "components/sections/home/Products";
import Tools from "components/sections/home/Tools";
import ProductsAnimation from "components/sections/home/ProductsAnimation";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import Map from "components/Map";

const Home = () => {
  const { t } = useTranslation("home");

  const isMobile = useMediaQuery({ maxWidth: "768px" });
  return (
    <main>
      <PageHeader title="Home | Smartcomply" />
      <div className="hero">
        <Navbar />
        <HeroSection />
      </div>

      <Tools />
      {isMobile ? (
        <Products />
      ) : (
        <section className=" px-14 py-20 max-md:px-8 max-[520px]:px-4 bg-white">
          <div className="mb-14 flex items-center justify-center flex-col lg:max-w-[70%] max-[1200px]:max-w-[70%] max-w-[60%] mx-auto">
            <h3 className="text-[#0D0D12] font-bold text-4xl lg:text-5xl text-center mb-4">
              {t("section3.title")}
            </h3>
            <p className="text-[#666D80] font-[Manrope] text-center text-sm">
              {t("section3.subtitle")}
            </p>
          </div>
          <ProductsAnimation />
        </section>
      )}

      <Map />
      <Brands />
      <Footer />
    </main>
  );
};

export default Home;
