import Skeleton from "components/Skeleton";
import moment from "moment";
import { useState, useRef, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import BlogCard, { PostSkeleton } from "./BlogCard";
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  CloseOutlined,
  DownOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import ReactPaginate from "react-paginate";
import secure from "assets/sc-secure.svg";
import academy from "assets/sc-academy.svg";
import intel from "assets/sc-intel.svg";
import adhere from "assets/sc-adhere-new.svg";
import { shuffleArray } from "utils";

const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
const BLOG_ID_SECURE = process.env.REACT_APP_GOOGLE_BLOG_ID_SECURE;
const BLOG_ID_ADHERE = process.env.REACT_APP_GOOGLE_BLOG_ID_ADHERE;
const BLOG_ID_INTEL = process.env.REACT_APP_GOOGLE_BLOG_ID_INTEL;

const AllPosts = () => {
  const { t } = useTranslation("home");

  const scrollTargetRef = useRef(null);

  const [posts, setPosts] = useState({ secure: [], adhere: [], intel: [] });

  const [loading, setLoading] = useState(true);
  const [query, setQuery] = useState("");
  const [itemOffset, setItemOffset] = useState(0);

  const [activeTab, setActiveTab] = useState(0);
  const [openTabs, setOpenTabs] = useState(false);
  const tabs = [
    "All",
    "Smartcomply Secure",
    "Adhere by Smartcomply",
    "SmartcomplyIntel",
    "Smartcomply Academy",
  ];

  const OFFSET = 3;

  const modifiedPosts = (data, category = null) => {
    if (!Array.isArray(data)) return data;
    return data?.map((post) => {
      const { replies, ...others } = post;
      return {
        ...others,
        labels: post?.labels?.map((label) => label?.toLowerCase()) || [],
        ...(category ? { category } : {}),
      };
    });
  };

  const getAllPosts = async () => {
    setLoading(true);
    try {
      const secure_response = await fetch(
        `https://www.googleapis.com/blogger/v3/blogs/${BLOG_ID_SECURE}/posts?key=${API_KEY}`
      );
      const adhere_response = await fetch(
        `https://www.googleapis.com/blogger/v3/blogs/${BLOG_ID_ADHERE}/posts?key=${API_KEY}`
      );
      const intel_response = await fetch(
        `https://www.googleapis.com/blogger/v3/blogs/${BLOG_ID_INTEL}/posts?key=${API_KEY}`
      );
      setLoading(false);
      const secure_data = await secure_response.json();
      const adhere_data = await adhere_response.json();
      const intel_data = await intel_response.json();

      if (
        secure_response.status === 200 ||
        adhere_response.status === 200 ||
        intel_response.status === 200
      ) {
        // if (
        //   ![...secure_data.items, ...adhere_data.items, ...intel_data.items]
        //     .length
        // )
        //   setNoPosts(true);

        setPosts({
          secure: modifiedPosts(secure_data?.items, "secure"),
          adhere: modifiedPosts(adhere_data?.items, "adhere"),
          intel: modifiedPosts(intel_data?.items, "intel"),
        });
      }
    } catch (error) {
      setLoading(false);
      toast.error(`${t("toast.posts")}`);
    }
  };

  const filtered_posts = useMemo(() => {
    let filteredItems = shuffleArray([
      ...posts.adhere,
      ...posts.secure,
      ...posts.intel,
    ]);

    if (activeTab !== 0) {
      filteredItems =
        activeTab === 0
          ? filteredItems
          : activeTab === 1
          ? posts.secure
          : activeTab === 2
          ? posts.adhere
          : activeTab === 3
          ? posts.intel
          : [];
    }

    if (query) {
      filteredItems = filteredItems?.filter((post) =>
        post.title?.toLowerCase()?.includes(query)
      );
    }

    return filteredItems;
  }, [posts, activeTab, query]);

  const handleQueryChange = (e) => setQuery(e.target.value.toLowerCase());

  const mostRecentPosts = useMemo(() => {
    const data =
      activeTab === 0
        ? shuffleArray([...posts.adhere, ...posts.secure, ...posts.intel])
        : activeTab === 1
        ? posts.secure
        : activeTab === 2
        ? posts.adhere
        : activeTab === 3
        ? posts.intel
        : [];

    if (data?.length === 0) return [];

    return data
      ?.sort((a, b) => {
        return moment(b.published).isAfter(moment(a.published)) ? 1 : -1;
      })
      ?.slice(0, 3);
  }, [activeTab, posts]);

  useEffect(() => {
    getAllPosts();
  }, []);

  useEffect(() => {
    setItemOffset(0);
  }, [activeTab]);

  const endOffset = itemOffset + OFFSET;
  const currentPosts = [...filtered_posts].slice(itemOffset, endOffset);
  const pageCount = Math.ceil(filtered_posts.length / OFFSET);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * OFFSET) % filtered_posts.length;
    setItemOffset(newOffset);
  };

  const handleTabSelection = (tabId) => {
    if (isNaN(tabId)) {
      throw new Error("Tab ID must be a number");
    }
    setActiveTab(tabId);
    setOpenTabs(false);
  };

  return (
    <>
      <section className=" px-[20%] pt-10 max-md:px-8 max-[520px]:px-4 max-lg:pt-14 max-md:pt-10 bg-white">
        <section className="flex flex-col justify-center items-center gap-12 max-md:gap-6">
          <div className="hidden md:block no-scrollbar w-full overflow-y-auto whitespace-nowrap mx-auto text-left justify-between items-start gap-2 text-[15px]">
            {tabs.map((tab, key) => (
              <div
                key={tab.key}
                className={`${
                  activeTab === key
                    ? "text-[#1B2761] font-[PoppinsSemibold] border-b-2 border-[#1B2761]"
                    : "text-[#667085] font-[Poppins] cursor-pointer"
                } px-3 py-4 inline-flex min-w-[90px] justify-center`}
                onClick={() => setActiveTab(key)}
              >
                {tab?.toLowerCase() === "all" ? t(`blog.all`) : tab}
              </div>
            ))}
          </div>
          <div className="relative w-full md:hidden">
            {/* <div
              className="h-16 px-6 flex items-center justify-between bg-[#F3F5FC] rounded-md"
              onClick={() => setOpenTabs(!openTabs)}
            >
              <p className="">{tabs[activeTab]}</p>
              <DownOutlined
                className={`transform ${
                  openTabs ? "group-hover:-rotate-180" : ""
                } transition duration-150 ease-in-out text-[12px]`}
              />
            </div> */}
            <div className="relative z-[40] w-full">
              <div className="group inline-block w-full">
                <div
                  className="h-16 px-6 flex items-center justify-between bg-[#F3F5FC] rounded-md w-full"
                  onClick={() => setOpenTabs(!openTabs)}
                >
                  <p className="">
                    {tabs[activeTab]?.toLowerCase() === "all"
                      ? t(`blog.all`)
                      : tabs[activeTab]}
                  </p>
                  <DownOutlined
                    className={`transform ${
                      openTabs ? "group-hover:-rotate-180" : ""
                    } transition duration-150 ease-in-out text-[12px]`}
                  />
                </div>
                {openTabs ? (
                  <div className="w-full">
                    <ul className="w-full pt-4 transform scale-0 group-hover:scale-100 absolute left-0 transition duration-150 space-y-2 ease-in-out origin-top z-40">
                      <div
                        ref={scrollTargetRef}
                        className="font-[Poppins] w-full rounded-lg  max-h-[400px] overflow-y-auto w-[340px] p-8 max-[450px]:p-6 space-y-3 bg-white relative border border-b-[#E8E9EF] shadow-[0px_4px_55px_5px_#12121226]"
                      >
                        <div
                          className={`p-5 border cursor-pointer border-[#F1F1F1] hover:border-[#F4C5E6] hover:bg-[#FFE5F7B2] ${
                            activeTab === 0
                              ? "border-[#F4C5E6] bg-[#FFE5F7B2]"
                              : ""
                          } rounded-lg`}
                        >
                          <div
                            onClick={() => handleTabSelection(0)}
                            className="flex flex-row items-center cursor-pointer justify-start space-x-3"
                          >
                            <div>
                              <h4 className="font-[Poppins] capitalize font-[600] text-[15px] text-black">
                                {t(`blog.all`)}
                              </h4>
                            </div>
                          </div>
                        </div>
                        <div
                          className={`p-5 border cursor-pointer border-[#F1F1F1] hover:border-[#F4C5E6] hover:bg-[#FFE5F7B2] ${
                            activeTab === 1
                              ? "border-[#F4C5E6] bg-[#FFE5F7B2]"
                              : ""
                          } rounded-lg`}
                        >
                          <div
                            onClick={() => handleTabSelection(1)}
                            className="flex flex-row items-center cursor-pointer justify-start space-x-3"
                          >
                            <img
                              src={secure}
                              alt="secure"
                              className="w-6 h-6 flex-shrink-0"
                            />
                            <div>
                              <h4 className="font-[Poppins] font-[600] text-[15px] text-black">
                                Smartcomply Secure
                              </h4>
                            </div>
                          </div>
                        </div>
                        <div
                          className={`p-5 border cursor-pointer border-[#F1F1F1] hover:border-[#F4C5E6] hover:bg-[#FFE5F7B2] ${
                            activeTab === 2
                              ? "border-[#F4C5E6] bg-[#FFE5F7B2]"
                              : ""
                          } rounded-lg`}
                        >
                          <div
                            onClick={() => handleTabSelection(2)}
                            className="flex flex-row items-center cursor-pointer justify-start space-x-3"
                          >
                            <img
                              src={adhere}
                              alt="adhere"
                              className="w-6 h-6 flex-shrink-0"
                            />
                            <div>
                              <h4 className="font-[Poppins] font-[600] text-[15px] text-black">
                                Adhere by Smartcomply
                              </h4>
                            </div>
                          </div>
                        </div>
                        <div
                          className={`p-5 border cursor-pointer border-[#F1F1F1] hover:border-[#F4C5E6] hover:bg-[#FFE5F7B2] ${
                            activeTab === 3
                              ? "border-[#F4C5E6] bg-[#FFE5F7B2]"
                              : ""
                          } rounded-lg`}
                        >
                          <div
                            onClick={() => handleTabSelection(3)}
                            className="flex flex-row items-center cursor-pointer justify-start space-x-3"
                          >
                            <img
                              src={intel}
                              alt="intel"
                              className="w-6 h-6 flex-shrink-0"
                            />
                            <div>
                              <h4 className="font-[Poppins] font-[600] text-[15px] text-black">
                                SmartcomplyIntel
                              </h4>
                            </div>
                          </div>
                        </div>
                        <div
                          className={`p-5 border cursor-pointer border-[#F1F1F1] hover:border-[#F4C5E6] hover:bg-[#FFE5F7B2] ${
                            activeTab === 4
                              ? "border-[#F4C5E6] bg-[#FFE5F7B2]"
                              : ""
                          } rounded-lg`}
                        >
                          <div
                            onClick={() => handleTabSelection(4)}
                            className="flex flex-row items-center cursor-pointer justify-start space-x-3"
                          >
                            <img
                              src={academy}
                              alt="academy"
                              className="w-6 h-6 flex-shrink-0"
                            />
                            <div>
                              <h4 className="font-[Poppins] font-[600] text-[15px] text-black">
                                Smartcomply Academy
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ul>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </section>
      </section>
      <section className=" px-14 py-14 max-md:px-8 max-[520px]:px-4 max-lg:py-14 max-md:py-8 bg-white">
        <>
          <div className="w-full mb-8 flex items-center justify-between gap-5 max-md:items-start">
            {loading ? (
              <div className="flex gap-10 items-center justify-between w-full">
                <div className="">
                  <div className="hidden gap-3 md:flex">
                    <Skeleton styles="w-64 h-10 rounded-3xl" />
                  </div>
                </div>
              </div>
            ) : mostRecentPosts?.length ? (
              <>
                <h4 className="font-bold text-left text-xl capitalize">
                  {t(`blog.recentHeader`)}
                </h4>
              </>
            ) : null}
          </div>

          <div className="w-full">
            {loading ? (
              <>
                <div className="hidden md:grid grid-cols-2 max-[920px]:grid-cols-2 max-[570px]:grid-cols-1 gap-6 max-[900px]:gap-5">
                  <PostSkeleton key={"recentskeleton" + 0} />
                  <div className="flex flex-col gap-4">
                    <PostSkeleton key={"recentskeleton" + 1} recent />
                    <PostSkeleton key={"recentskeleton" + 2} recent />
                  </div>
                </div>
                <div className="md:hidden grid grid-cols-2 max-[570px]:grid-cols-1 gap-5">
                  <PostSkeleton key={"recentskeleton" + 0} />
                  <PostSkeleton key={"recentskeleton" + 1} recent />
                  <PostSkeleton key={"recentskeleton" + 2} recent />
                </div>
              </>
            ) : (
              <>
                {mostRecentPosts?.length ? (
                  <>
                    <div className="hidden md:grid grid-cols-2 max-[1200px]:grid-cols-[35%_1fr] max-md:grid-cols-2 gap-6 max-[900px]:gap-5">
                      <BlogCard
                        key={"recent-" + mostRecentPosts[0].id}
                        post={mostRecentPosts[0]}
                        category={mostRecentPosts[0].category}
                      />

                      <div className="md:flex md:flex-col hidden gap-4">
                        {mostRecentPosts.slice(1).map((card) => (
                          <BlogCard
                            key={"recent-" + card.id}
                            post={card}
                            recent
                            category={card.category}
                          />
                        ))}
                      </div>
                    </div>
                    <div className="md:hidden grid grid-cols-2 gap-5 max-[570px]:grid-cols-1">
                      {mostRecentPosts.map((card) => (
                        <BlogCard
                          key={"recent-" + card.id}
                          post={card}
                          recent
                          category={card.category}
                        />
                      ))}
                    </div>
                  </>
                ) : null}
              </>
            )}
          </div>
        </>

        <section className="w-full flex flex-col gap-0 max-md:gap-10 justify-center items-center mt-14 max-md:mt-8">
          <div className="flex flex-col w-full">
            <div className="w-full flex items-center justify-between gap-5 max-md:items-start">
              {loading ? (
                <div className="flex gap-10 items-center justify-between w-full">
                  <div className="">
                    <div className="hidden gap-3 md:flex">
                      <Skeleton styles="w-64 h-10 rounded-3xl" />
                    </div>

                    <Skeleton styles="w-64 max-[450px]:w-32 h-10 rounded-md md:hidden block" />
                  </div>
                  <div className="">
                    <Skeleton styles="w-32 h-8 rounded-md" />
                  </div>
                </div>
              ) : (
                <>
                  <h4 className="font-bold text-left text-xl capitalize">
                    {tabs[activeTab]?.toLowerCase() === "all"
                      ? t(`blog.all`)
                      : tabs[activeTab]}{" "}
                    {t(`blog.postsTitle`)}
                  </h4>
                  <div
                    className="relative rounded-md md:block hidden"
                    style={{ border: "1px solid #E2E8F0" }}
                  >
                    <input
                      type="search"
                      onChange={handleQueryChange}
                      value={query}
                      placeholder={t(`blog.search`)}
                      className="placeholder:capitalize !border-1 !border-gray-200 h-[40px] max-w-64 bg-gray-50 focus:outline-0 focus:border-1 focus:border-gray-200 focus-within:!shadow-none !placeholder:text-gray-400 text-gray-400 font-medium text-sm pl-7 rounded-md"
                    />

                    <div className="cursor-pointer absolute h-[40px] flex justify-start items-center w-fit left-2 top-0 absolute left-2 top-1/2 -translate-y-1/2 object-contain">
                      <SearchOutlined className="text-sm text-[#8F9099] w-3.5 h-3.5" />
                    </div>

                    <button
                      className="absolute right-2 top-1/2 hidden -translate-y-1/2 scale-[.8] opacity-50 max-lg:group-focus-within:block lg:hidden"
                      onClick={() => setQuery("")}
                    >
                      <CloseOutlined
                        onClick={() => setOpenTabs(false)}
                        className="text-sm text-[#8F9099] w-3.5 h-3.5"
                      />
                    </button>
                  </div>

                  <div className="cursor-pointer md:hidden flex group absolute focus-within:w-full  max-lg:right-0 max-lg:focus-within:bg-background lg:relative lg:w-auto lg:focus-within:w-auto">
                    <SearchOutlined className="text-[20px] text-[#8F9099] pointer-events-none absolute left-[38px] max-[520px]:left-[23px] w-[20px] h-[20px] top-1/2 -translate-y-1/2 object-contain" />

                    <input
                      type="text"
                      onChange={handleQueryChange}
                      value={query}
                      placeholder={t(`blog.search`)}
                      className="cursor-pointer placeholder:capitalize !placeholder:text-gray-400 text-gray-400 font-medium text-sm h-10 bg-white border border-[#E2E8F0]  w-6 rounded px-2 pl-6 max-lg:group-focus-within:w-full max-lg:group-focus-within:pl-9 group-focus-within:!outline-none mx-[32px] max-[520px]:mx-[16px]"
                    />

                    <button
                      className="absolute right-[36px] max-[520px]:right-[25px] w-[20px] h-[20px] top-1/2 hidden -translate-y-1/2 scale-[.8] opacity-50 max-lg:group-focus-within:block lg:hidden"
                      onClick={() => setQuery("")}
                    >
                      <CloseOutlined
                        onClick={() => setOpenTabs(false)}
                        className="text-sm text-[#8F9099] w-3.5 h-3.5"
                      />
                    </button>
                  </div>
                </>
              )}
            </div>

            <div className="w-full mt-12 max-md:mt-8">
              {loading ? (
                <div className="grid grid-cols-3 max-[920px]:grid-cols-2 max-[570px]:grid-cols-1 gap-6 max-[900px]:gap-5">
                  {Array(3)
                    .fill(0)
                    .map((_arg, index) => (
                      <PostSkeleton key={"skeleton" + index} />
                    ))}
                </div>
              ) : (
                <>
                  {currentPosts?.length ? (
                    <div className="grid grid-cols-3 max-[920px]:grid-cols-2 max-[570px]:grid-cols-1 gap-6 max-[900px]:gap-5">
                      {currentPosts.map((card, index) => (
                        <BlogCard
                          key={index}
                          post={card}
                          category={card.category}
                        />
                      ))}
                    </div>
                  ) : (
                    <h4 className="my-8 flex justify-center items-center capitalize font-extrabold text-black text-xl md:text-2xl">
                      {t("blog.noPosts")}
                    </h4>
                  )}
                </>
              )}
            </div>
          </div>
        </section>
        <ReactPaginate
          breakLabel="..."
          nextLabel={
            <div className="flex gap-2 items-center justify-end max-[400px]:flex-col-reverse max-[400px]:items-end">
              <p className="text-[14px] text-[#667085] font-[Inter] capitalize">
                {t(`blog.next`)}
              </p>
              <ArrowRightOutlined className="text-[#667085] font-[Inter] text-[14px]" />
            </div>
          }
          onPageChange={handlePageClick}
          // pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel={
            <div className="flex gap-2 items-center justify-start max-[400px]:flex-col max-[400px]:items-start">
              <ArrowLeftOutlined className="text-[#667085] font-[Inter] text-[14px]" />
              <p className="text-[14px] text-[#667085] font-[Inter] capitalize">
                {t(`blog.previous`)}
              </p>
            </div>
          }
          renderOnZeroPageCount={null}
          className="border-t border-[#EAECF0] pt-8 mt-8 w-full flex items-center justify-start"
          pageClassName="text-[#667085] px-4 py-1.5 w-fit  text-[14px]"
          activeClassName="bg-[#F9F5FF] rounded-[10px]"
          containerClassName=""
          previousClassName="w-fit flex-1"
          nextClassName="w-fit flex-1"
        />
      </section>
    </>
  );
};

export default AllPosts;
