export const capitalize = (s) => {
    if (!s) return "";
    return s[0].toUpperCase() + s.slice(1);
}

export function calculateReadingTime(wordCount) {
    // average reading speed (200 words per minute)
    const wordsPerMinute = 200;
    const wordsPerSecond = wordsPerMinute / 60;

    let timeInSeconds = wordCount / wordsPerSecond;
    let minutes = Math.floor(timeInSeconds / 60);
    let seconds = Math.floor(timeInSeconds % 60);

    // calculate hours and minutes if reading time exceeds 60 minutes
    let hours = Math.floor(minutes / 60);
    minutes = minutes % 60;

    if (hours > 0) {
        return `${hours} hour(s), ${minutes} minute(s)`;
    } else if (minutes > 0) {
        return `${minutes} minute(s)`;
    } else {
        return `${seconds} second(s)`;
    }
}

export function countWordsFromHTML(htmlContent) {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = htmlContent;

    const textContent = tempDiv.textContent || tempDiv.innerText || "";

    const cleanedText = textContent.replace(/\s+/g, " ").trim();
    const words = cleanedText.split(" ");

    return words.length;
}

export function shuffleArray(arr) {
    if (!Array.isArray(arr)) return [];
    for (let i = arr.length - 1; i > 0; i--) {
        const randomIndex = Math.floor(Math.random() * (i + 1));
        [arr[i], arr[randomIndex]] = [arr[randomIndex], arr[i]];
    }
    return arr;
}