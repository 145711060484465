import Navbar from "components/Navbar";
import Footer from "components/sections/home/HomeFooter";
import PageHeader from "components/PageHeader";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const CookieNotice = () => {
  const { t } = useTranslation("home");

  return (
    <main>
      <PageHeader title="Privacy Policy | Smartcomply" />
      <div className="hero">
        <Navbar />
        <div className="flex flex-col w-full bg-white">
          <div className="flex flex-col w-full">
            <div className="mt-[85px] min-h-[450px] max-md:mt-[70px] px-14 pt-14 pb-4 max-md:px-8 max-[520px]:px-4 max-lg:pt-10 relative bg-cover bg-center w-full min-h-[300px] max-md:min-h-[300px] z-[5] relative bg-[linear-gradient(2.3deg,#FFFFFF_44.89%,#E5F0FF_81.6%)]">
              <div className="flex flex-col space-y-8 md:space-y-0 md:flex-row md:justify-between md:space-x-10 py-4 gap-4">
                <div className="w-full space-y-2">
                  <div>
                    <h2 className="font-bold text-[#0c111d] text-[50px]  max-w-[80%] max-lg:text-[40px] max-md:text-[33px] max-md:max-w-[280px] leading-tight text-[#0B1029] max-md:text-center max-md:mx-auto">
                      {t("footer.footerLinks.cookieNotice")}
                    </h2>
                  </div>

                  <div className="">
                    <p className="text-sm text-[PoppinsMedium] max-md:text-center text-black">
                      {t("blog.header.subtitle")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="px-[20%] max-lg:px-[10%] max-md:px-8 max-[520px]:px-4 relative bg-cover bg-center w-full min-h-[300px] bg-white text-sm">
            <div className="pb-28">
              <div className="">
                <ul className="font-[Manrope] text-[#36394A] list-style-none list-inside  marker:text-[#0D0D12] marker:text-xl space-y-8">
                  <div className="mb-4">
                    <p className="text-sm font-semibold">
                      Last updated at: 19th November, 2024
                    </p>
                  </div>

                  <li className="mb-4">
                    <h3 className="text-3xl text-[#0D0D12] font-bold inline max-md:text-2xl text-left">
                      Who We Are
                    </h3>
                    <p className="text-sm mt-4">
                      We are Smartcomply Technology Solutions Limited
                      (hereinafter referred to as &quot;Smartcomply&quot;,
                      &quot;we&quot;, &quot;our&quot;, or &quot;us&quot;), an
                      automated and AI-powered compliance and cybersecurity
                      company.
                      <br />
                      We specialize in helping businesses protect their digital
                      assets, meet regulatory requirements, and improve their
                      overall security, using the latest technology and
                      expertise to help organizations reduce risks, simplify
                      compliance, and safeguard sensitive information from cyber
                      threats.
                      <br />
                      Our address is The Bunker Building 279 Herbert Macaulay
                      Way Yaba, Lagos, Nigeria.
                    </p>
                  </li>

                  <li className="mb-4">
                    <h3 className="text-3xl text-[#0D0D12] font-bold inline max-md:text-2xl text-left">
                      Cookie Notice Overview
                    </h3>
                    <p className="text-sm mt-4">
                      This Cookie Notice explains what Cookies are and how we
                      use them. Cookies do not typically contain any information
                      that personally identifies a user. However, personal
                      information that we store about you may be linked to the
                      information stored in and obtained from Cookies. For more
                      details on how we use, store and keep your data secure,
                      see our{" "}
                      <Link
                        to="/privacy-policy"
                        className="font-semibold underline"
                      >
                        Privacy Notice.
                      </Link>
                      .
                    </p>
                  </li>

                  <li className="mb-4">
                    <h3 className="text-3xl text-[#0D0D12] font-bold inline max-md:text-2xl text-left">
                      What Are Cookies
                    </h3>
                    <p className="text-sm mt-4">
                      Cookies are small pieces of data stored in text files
                      saved on your mobile device or computer when you visit a
                      website. Cookies allow the website&apos;s server to record
                      and store your actions and preferences, such as login
                      information, user history, language settings, font
                      settings, color settings, and other display preferences,
                      over a particular period.
                      <br />
                      Cookies ensure visitors do not have to continuously
                      re-enter their details or information whenever they
                      revisit the site or surf between pages.
                      <br />
                      Cookies are used to remember your preferences for single
                      visits through sessional cookies or multiple repeat visits
                      through permanent cookies.
                    </p>
                  </li>

                  <li className="mb-4">
                    <h3 className="text-3xl text-[#0D0D12] font-bold inline max-md:text-2xl text-left">
                      How We Use Cookies
                    </h3>
                    <p className="text-sm mt-4">
                      Cookies are essential for our websites/webapps to function
                      optimally and enhance performance for a better user
                      experience. The websites/webapps administrator(s) controls
                      all cookies used on the site and grants third-parties
                      access for statistics and analytics purposes. Cookies are
                      needed to identify visitors and remember their site
                      actions and online behaviors.
                      <br />
                      We use cookies for various reasons, which are explained in
                      detail below. Please know that cookie-related information
                      is not used to identify you personally.
                      <br />
                      <span className="font-semibold">
                        We use the following cookies on our Webapps
                      </span>
                      <ul className="flex list-disc flex-col gap-2 pl-6">
                        <li className="pl-2">
                          <b>Necessary cookies:</b> These cookies are essential
                          for the websites/webapps to function properly and
                          provide basic services.
                        </li>

                        <li className="pl-2">
                          <b>Analytics cookies:</b> They are used to understand
                          how visitors interact with the Websites/webapps. These
                          cookies help provide information on metrics the number
                          of visitors, bounce rate, traffic source, etc.
                        </li>

                        <li className="pl-2">
                          <b>Functional cookies:</b> They help to perform
                          certain functionalities like sharing the
                          Websites/Webapps’ content on social media platforms,
                          collecting feedback, and other third-party features.
                        </li>
                      </ul>
                    </p>
                  </li>

                  <div className="max-sm:overflow-y-auto">
                    <table className="my-2 table-fixed border-collapse border [&_td]:border [&_td]:p-2 [&_th]:border [&_th]:p-2">
                      <thead>
                        <tr>
                          <th>Domain</th>

                          <th>Description</th>

                          <th>Type</th>

                          <th>Expiration</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td>__cf_bm</td>

                          <td>
                            This cookie is used to distinguish between humans
                            and bots. This is beneficial for the website, in
                            order to make valid reports on the use of their
                            website.
                          </td>

                          <td>Necessary</td>

                          <td>Persistent, except cleared by user</td>
                        </tr>

                        <tr>
                          <td>1P_Jar</td>

                          <td>Used for google font on the app.</td>

                          <td>Functional</td>

                          <td>Persistent, except cleared by user</td>
                        </tr>

                        <tr>
                          <td>Crsftoken</td>

                          <td>
                            A CSRF token is a unique, secret, and unpredictable
                            value that is generated by the server-side
                            application and shared with the client.
                          </td>

                          <td>Necessary</td>

                          <td>Persistent, except cleared by user</td>
                        </tr>

                        <tr>
                          <td>session_id</td>

                          <td>
                            A session ID is a unique number that a Web
                            site&apos;s server assigns a specific user for the
                            duration of that user&apos;s visit (session)
                          </td>

                          <td>Necessary</td>

                          <td>Persistent, except cleared by user</td>
                        </tr>

                        <tr>
                          <td>_fbn</td>

                          <td>
                            Used by Facebook Analytics to track user
                            interactions and provide insights into website or
                            app usage
                          </td>

                          <td>Analytics</td>

                          <td>Persistent, except cleared by user</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div className="flex flex-col gap-2">
                    <h4 className="font-bold">
                      We use the following cookies for Websites:
                    </h4>

                    <h4 className="font-bold">Necessary</h4>

                    <p>
                      Necessary cookies are required to enable the basic
                      features of this site, such as providing secure log-in or
                      adjusting your consent preferences.
                    </p>
                  </div>

                  <div className="max-sm:overflow-y-auto">
                    <table className="my-4 border-collapse border [&_td]:border [&_td]:p-2 [&_th]:border [&_th]:p-2">
                      <thead>
                        <tr>
                          <th>Name</th>

                          <th>Purpose</th>

                          <th>Type</th>

                          <th>Expiration</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td>__cfruid</td>

                          <td>
                            This cookie is a part of the services provided by
                            Cloudflare - Including load-balancing, deliverance
                            of website content and serving DNS connection for
                            website operators.
                          </td>

                          <td>Necessary</td>

                          <td>Session</td>
                        </tr>

                        <tr>
                          <td>Sid</td>

                          <td>
                            This cookie preserves users state across page
                            requests
                          </td>

                          <td>Necessary</td>

                          <td>1 year</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div className="flex flex-col gap-2">
                    <h4 className="font-bold">Preference</h4>

                    <p>
                      Preference cookies enable a website to remember
                      information that changes the way the website behaves or
                      looks, like your preferred language or the region that you
                      are in.
                    </p>
                  </div>

                  <div className="max-sm:overflow-y-auto">
                    <table className="my-4 border-collapse border [&_td]:border [&_td]:p-2 [&_th]:border [&_th]:p-2">
                      <thead>
                        <tr>
                          <th>Name</th>

                          <th>Purpose</th>

                          <th>Type</th>

                          <th>Expiration</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td>Locale</td>

                          <td>
                            The cookie determines the preferred language and
                            country-setting of the visitor - This allows the
                            website to show content most relevant to that region
                            and language.
                          </td>

                          <td>Functional</td>

                          <td>Persistent</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div className="flex flex-col gap-2">
                    <h4 className="font-bold">Statistics</h4>

                    <p>
                      Statistic cookies help website owners to understand how
                      visitors interact with websites by collecting and
                      reporting information anonymously
                    </p>
                  </div>

                  <div className="max-sm:overflow-y-auto">
                    <table className="my-4 border-collapse border [&_td]:border [&_td]:p-2 [&_th]:border [&_th]:p-2">
                      <thead>
                        <tr>
                          <th>Name</th>

                          <th>Purpose</th>

                          <th>Type</th>

                          <th>Expiration</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td>_ga</td>

                          <td>
                            Registers a unique ID that is used to generate
                            statistical data on how the visitor uses the
                            website.
                          </td>

                          <td>Analytics</td>

                          <td>2 years</td>
                        </tr>

                        <tr>
                          <td>_ga_#</td>

                          <td>
                            Used by Google Analytics to collect data on the
                            number of times a user has visited the website as
                            well as dates for the first and most recent visit
                          </td>

                          <td>Analytics</td>

                          <td>2 years</td>
                        </tr>

                        <tr>
                          <td>_gat</td>

                          <td>
                            Used by Google Analytics to throttle request rate.
                          </td>

                          <td>Analytics</td>

                          <td>1 day</td>
                        </tr>

                        <tr>
                          <td>_gid</td>

                          <td>
                            Registers a unique ID that is used to generate
                            statistical data on how the visitor uses the website
                          </td>

                          <td>Analytics</td>

                          <td>1 day</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div className="flex flex-col gap-2">
                    <h4 className="font-bold">Marketing</h4>

                    <p>
                      Marketing cookies are used to track visitors across
                      websites. The intention is to display ads that are
                      relevant and engaging for the individual user and thereby
                      more valuable for publishers and third-party advertisers.
                    </p>
                  </div>

                  <div className="max-sm:overflow-y-auto">
                    <table className="my-4 border-collapse border [&_td]:border [&_td]:p-2 [&_th]:border [&_th]:p-2">
                      <thead>
                        <tr>
                          <th>Name</th>

                          <th>Purpose</th>

                          <th>Type</th>

                          <th>Expiration</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td> _fbp</td>

                          <td>
                            Used by Facebook to deliver a series of
                            advertisement products such as real time bidding
                            from third party advertisers.
                          </td>

                          <td>Analytics</td>

                          <td>3 months</td>
                        </tr>

                        <tr>
                          <td>ads/ga-audiences</td>

                          <td>
                            Used by Google AdWords to re-engage visitors that
                            are likely to convert to customers based on the
                            visitor&apos;s online behavior across websites
                          </td>

                          <td>Analytics</td>

                          <td>Session</td>
                        </tr>

                        <tr>
                          <td>lastExternalReferrer</td>

                          <td>
                            Detects how the user reached the website by
                            registering their last URL-address.
                          </td>

                          <td>Analytics</td>

                          <td>Persistent</td>
                        </tr>

                        <tr>
                          <td>lastExternaRefererTime</td>

                          <td>
                            Detects how the user reached the website by
                            registering their last URL-address.
                          </td>

                          <td>Analytics</td>

                          <td>Persistent</td>
                        </tr>

                        <tr>
                          <td>Uid</td>

                          <td>
                            Unique user ID that recognizes the user on returning
                            visits
                          </td>

                          <td>Functional</td>

                          <td>Persistent</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <li className="mb-4">
                    <h3 className="text-3xl text-[#0D0D12] font-bold inline max-md:text-2xl text-left">
                      How to Manage and Delete Your Cookie
                    </h3>
                    <p className="text-sm mt-4">
                      Unless you have adjusted your browser settings to refuse
                      cookies, our system will issue cookies when you direct
                      your browser to our Websites/Webapps. You can refuse to
                      accept cookies by activating the appropriate setting on
                      your browser. If you do not wish to accept cookies
                      connected with your use of our Websites/Webapps, you may
                      delete and block or disable cookies via your browser
                      settings; see below for more information on how to do
                      this.:
                    </p>
                    <ul className="flex list-disc flex-col gap-2 pl-6">
                      <li className="pl-2">
                        <a
                          rel="noreferrer"
                          href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-disable-cookies-website-preferences&redirectlocale=en-US"
                          target="_blank"
                          className="font-semibold text-primary-accent underline"
                        >
                          Firefox
                        </a>
                      </li>

                      <li className="pl-2">
                        <a
                          rel="noreferrer"
                          href="https://privacy.microsoft.com/en-us/privacystatement"
                          target="_blank"
                          className="font-semibold text-primary-accent underline"
                        >
                          Internet explorer
                        </a>
                      </li>

                      <li className="pl-2">
                        <a
                          rel="noreferrer"
                          href="https://support.google.com/chrome/answer/95647?hl=en"
                          target="_blank"
                          className="font-semibold text-primary-accent underline"
                        >
                          Google chrome
                        </a>
                      </li>
                    </ul>
                    <p className="text-sm mt-4">
                      If you have any questions or concerns about this Cookie
                      Notice, you can contact by email at:{" "}
                      <Link
                        to="mailto:ope@smartcomply.com"
                        className="text-primary-40 underline"
                      >
                        ope@smartcomply.com
                      </Link>
                    </p>
                    <p className="text-sm mt-4">
                      For this Notice, the supervisory authority is the{" "}
                      <span className="font-semibold">
                        Nigeria Data Protection Commission (NDPC)
                      </span>
                      , and the complaint can be sent via email at{" "}
                      <Link
                        to="mailto:info@ndpc.gov.ng"
                        className="text-primary-40 underline"
                      >
                        info@ndpc.gov.ng
                      </Link>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </main>
  );
};

export default CookieNotice;
