import { useTranslation } from "react-i18next";
// import Slider from "react-slick";
// import "../../carousel/carousel.css";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

import redtech from "assets/new-logos/redtech.png";
import abeg from "assets/new-logos/abeg.png";
import unitybank from "assets/new-logos/unity-bank.png";
import tellerone from "assets/new-logos/tellerone.png";
import nova from "assets/new-logos/nova-bank.png";
import piggyvest from "assets/new-logos/piggyvest.png";
import suntrust from "assets/new-logos/suntrust-bank.png";
import pivo from "assets/new-logos/pivo.png";
import brbcapital from "assets/new-logos/brb-capital.png";
import lenco from "assets/new-logos/lenco.png";
import credrails from "assets/new-logos/credrails.png";
import activeedge from "assets/new-logos/activeedge.png";
import dot from "assets/new-logos/dot.png";
import vesti from "assets/new-logos/vesti.png";
import spotflow from "assets/new-logos/spotflow.png";
import evolve from "assets/new-logos/evolve-credit.png";
import linq from "assets/new-logos/linq.png";
import rightcard from "assets/new-logos/right-card.png";
import raven from "assets/new-logos/raven.png";
import accelerex from "assets/new-logos/accelerex.png";
import tix from "assets/new-logos/tix.png";
import earnipay from "assets/new-logos/earnipay.png";
import fcmb from "assets/new-logos/fcmb.png";
import intron from "assets/new-logos/intron.png";
import flex from "assets/new-logos/flex.png";
import novac from "assets/new-logos/novac.png";
import hugo from "assets/new-logos/hugo.png";
import zone from "assets/new-logos/zone.png";
import qore from "assets/new-logos/qore.png";
import amfb from "assets/new-logos/AFMB.png";
import wired_assurance from "assets/new-logos/wired-assurance.png";
import afl from "assets/new-logos/afl.png";
import hammer from "assets/new-logos/Hammergames.png";
import eden from "assets/new-logos/edencore.png";
import transcend from "assets/new-logos/transxend.png";
import veend from "assets/new-logos/vend.png";
import oval from "assets/new-logos/oval.png";
import moneypigeon from "assets/new-logos/moneypigeon.png";
import smileid from "assets/new-logos/smile-id.png";
import sendsprint from "assets/new-logos/sendsprint.png";
import crust from "assets/new-logos/crust.png";
import simplify from "assets/new-logos/simplify.png";
import daccubin from "assets/new-logos/daccubin.png";

const Brands = () => {
  const { t } = useTranslation("home");
  // const settings = {
  //   dots: false,
  //   infinite: true,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   autoplay: true,
  //   speed: 1000,
  //   autoplaySpeed: 1000,
  //   vertical: true,
  //   verticalSwiping: true,
  //   nextArrow: <></>,
  //   prevArrow: <></>,
  // };

  return (
    <section className="px-14 pt-16 pb-20 max-md:px-8 max-[520px]:px-4 max-lg:py-14 bg-white">
      <h4 className="text-[#000000] font-[ManropeMedium] uppercase text-center mb-16 max-lg:mb-12 tracking-widest">
        {t("header.trustedText")}
      </h4>
      <div className="flex flex-col gap-10 max-[450px]:grid max-[450px]:grid-cols-4">
        {/* <Slider {...settings}> */}
        <div className="!flex gap-6 gap-y-10 max-lg:gap-x-4 items-center justify-center w-full max-[800px]:!grid max-[800px]:grid-cols-5 max-[450px]:!contents">
          <img
            className="max-w-[65px] max-[375px]:max-w-[55px] max-h-[25px] justify-self-center"
            src={redtech}
            alt=""
          />

          <img
            className="max-w-[65px] max-[375px]:max-w-[55px] max-h-[25px] justify-self-center"
            src={abeg}
            alt=""
          />

          <img
            className="max-w-[65px] max-[375px]:max-w-[55px] max-h-[25px] justify-self-center"
            src={unitybank}
            alt=""
          />

          <img
            className="max-w-[65px] max-[375px]:max-w-[55px] max-h-[25px] justify-self-center"
            src={tellerone}
            alt=""
          />

          <img
            className="max-w-[65px] max-[375px]:max-w-[55px] max-h-[25px] justify-self-center"
            src={nova}
            alt=""
          />

          <img
            className="max-w-[65px] max-[375px]:max-w-[55px] max-h-[25px] justify-self-center"
            src={piggyvest}
            alt=""
          />

          <img
            className="max-w-[65px] max-[375px]:max-w-[55px] max-h-[25px] justify-self-center"
            src={suntrust}
            alt=""
          />

          <img
            className="max-w-[65px] max-[375px]:max-w-[55px] max-h-[25px] justify-self-center"
            src={pivo}
            alt=""
          />

          <img
            className="max-w-[65px] max-[375px]:max-w-[55px] max-h-[25px] justify-self-center"
            src={brbcapital}
            alt=""
          />

          <img
            className="max-w-[65px] max-[375px]:max-w-[55px] max-h-[25px] justify-self-center"
            src={lenco}
            alt=""
          />
        </div>

        <div className="!flex gap-6 gap-y-10 max-lg:gap-x-4 items-center justify-center w-full max-[800px]:!grid max-[800px]:grid-cols-5 max-[450px]:!contents">
          <img
            className="max-w-[65px] max-[375px]:max-w-[55px] max-h-[25px] justify-self-center"
            src={credrails}
            alt=""
          />

          <img
            className="max-w-[65px] max-[375px]:max-w-[55px] max-h-[25px] justify-self-center"
            src={activeedge}
            alt=""
          />

          <img
            className="max-w-[65px] max-[375px]:max-w-[55px] max-h-[25px] justify-self-center"
            src={dot}
            alt=""
          />

          <img
            className="max-w-[65px] max-[375px]:max-w-[55px] max-h-[25px] justify-self-center"
            src={vesti}
            alt=""
          />

          <img
            className="max-w-[65px] max-[375px]:max-w-[55px] max-h-[25px] justify-self-center"
            src={spotflow}
            alt=""
          />

          <img
            className="max-w-[65px] max-[375px]:max-w-[55px] max-h-[25px] justify-self-center"
            src={evolve}
            alt=""
          />

          <img
            className="max-w-[65px] max-[375px]:max-w-[55px] max-h-[25px] justify-self-center"
            src={linq}
            alt=""
          />

          <img
            className="max-w-[65px] max-[375px]:max-w-[55px] max-h-[25px] justify-self-center"
            src={rightcard}
            alt=""
          />

          <img
            className="max-w-[65px] max-[375px]:max-w-[55px] max-h-[25px] justify-self-center"
            src={raven}
            alt=""
          />

          <img
            className="max-w-[65px] max-[375px]:max-w-[55px] max-h-[25px] justify-self-center"
            src={accelerex}
            alt=""
          />
        </div>

        <div className="!flex gap-6 gap-y-10 max-lg:gap-x-4 items-center justify-center w-full max-[800px]:!grid max-[800px]:grid-cols-5 max-[450px]:!contents">
          <img
            className=" max-w-[65px] max-[375px]:max-w-[55px] max-h-[25px] justify-self-center"
            src={tix}
            alt=""
          />

          <img
            className="max-w-[65px] max-[375px]:max-w-[55px] max-h-[25px] justify-self-center"
            src={earnipay}
            alt=""
          />
          <img
            className="max-w-[65px] max-[375px]:max-w-[55px] max-h-[25px] justify-self-center"
            src={intron}
            alt=""
          />

          <img className="w-10" src={flex} alt="" />

          <img
            className="max-w-[65px] max-[375px]:max-w-[55px] max-h-[25px] justify-self-center"
            src={novac}
            alt=""
          />

          <img
            className="max-w-[65px] max-[375px]:max-w-[55px] max-h-[25px] justify-self-center"
            src={hugo}
            alt=""
          />

          <img
            className="max-w-[65px] max-[375px]:max-w-[55px] max-h-[25px] justify-self-center"
            src={zone}
            alt=""
          />

          <img
            className="max-w-[65px] max-[375px]:max-w-[55px] max-h-[25px] justify-self-center"
            src={qore}
            alt=""
          />

          <img
            className="max-w-[65px] max-[375px]:max-w-[55px] max-h-[25px] justify-self-center"
            src={amfb}
            alt=""
          />

          <img
            className="max-w-[65px] max-[375px]:max-w-[55px] max-h-[25px] justify-self-center"
            src={wired_assurance}
            alt=""
          />
        </div>
        <div className="!flex gap-6 gap-y-10 max-lg:gap-x-4 items-center justify-center w-full max-[800px]:!grid max-[800px]:grid-cols-5 max-[450px]:!contents">
          <img
            className="max-w-[65px] max-[375px]:max-w-[55px] max-h-[25px] justify-self-center"
            src={afl}
            alt=""
          />

          <img
            className="max-w-[65px] max-[375px]:max-w-[55px] max-h-[25px] justify-self-center"
            src={hammer}
            alt=""
          />

          <img
            className="max-w-[65px] max-[375px]:max-w-[55px] max-h-[25px] justify-self-center"
            src={eden}
            alt=""
          />

          <img
            className="max-w-[65px] max-[375px]:max-w-[55px] max-h-[25px] justify-self-center"
            src={transcend}
            alt=""
          />

          <img
            className="max-w-[65px] max-[375px]:max-w-[55px] max-h-[25px] justify-self-center"
            src={veend}
            alt=""
          />

          <img
            className="max-w-[65px] max-[375px]:max-w-[55px] max-h-[25px] justify-self-center"
            src={oval}
            alt=""
          />
          <img
            className="max-w-[65px] max-[375px]:max-w-[55px] max-h-[25px] justify-self-center"
            src={moneypigeon}
            alt=""
          />

          <img
            className="max-w-[65px] max-[375px]:max-w-[55px] max-h-[25px] justify-self-center"
            src={smileid}
            alt=""
          />

          <img
            className="max-w-[65px] max-[375px]:max-w-[55px] max-h-[25px] justify-self-center"
            src={sendsprint}
            alt=""
          />

          <img
            className="max-w-[65px] max-[375px]:max-w-[55px] max-h-[25px] justify-self-center"
            src={crust}
            alt=""
          />
        </div>
        <div className="!flex gap-6 gap-y-10 max-lg:gap-x-4 items-center justify-center w-full max-[800px]:!grid max-[800px]:grid-cols-5 max-[450px]:!contents">
          <img
            className="max-w-[65px] max-[375px]:max-w-[55px] max-h-[25px] justify-self-center"
            src={simplify}
            alt=""
          />

          <img
            className="max-w-[65px] max-[375px]:max-w-[55px] max-h-[25px] justify-self-center"
            src={daccubin}
            alt=""
          />
          <img
            className="max-w-[65px] max-[375px]:max-w-[55px] max-h-[25px] justify-self-center"
            src={fcmb}
            alt=""
          />
        </div>
        {/* </Slider> */}
      </div>
    </section>
  );
};

export default Brands;
