import Navbar from "components/Navbar";
import Footer from "components/sections/home/HomeFooter";
import PageHeader from "components/PageHeader";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Terms = () => {
  const { t } = useTranslation("home");

  return (
    <main>
      <PageHeader title="Terms | Smartcomply" />
      <div className="hero">
        <Navbar />
        <div className="flex flex-col w-full bg-white">
          <div className="flex flex-col w-full">
            <div className="mt-[85px] min-h-[450px] max-md:mt-[70px] px-14 pt-14 pb-4 max-md:px-8 max-[520px]:px-4 max-lg:pt-10 relative bg-cover bg-center w-full min-h-[300px] max-md:min-h-[300px] z-[5] relative bg-[linear-gradient(2.3deg,#FFFFFF_44.89%,#E5F0FF_81.6%)]">
              <div className="flex flex-col space-y-8 md:space-y-0 md:flex-row md:justify-between md:space-x-10 py-4 gap-4">
                <div className="w-full space-y-2">
                  <div>
                    <h2 className="font-bold text-[#0c111d] text-[50px]  max-w-[80%] max-lg:text-[40px] max-md:text-[33px] max-md:max-w-[280px] leading-tight text-[#0B1029] max-md:text-center max-md:mx-auto">
                      {t("footer.footerLinks.terms")}
                    </h2>
                  </div>

                  <div className="">
                    <p className="text-sm text-[PoppinsMedium] max-md:text-center text-black">
                      {t("blog.header.subtitle")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="px-[20%] max-lg:px-[10%] max-md:px-8 max-[520px]:px-4 relative bg-cover bg-center w-full min-h-[300px] bg-white text-sm">
            <div className="pb-28">
              <div className="">
                <ul className="font-[Manrope] text-[#36394A] list-style-none list-inside  marker:text-[#0D0D12] marker:text-xl space-y-8">
                  <div className="mb-4">
                    <p className="text-sm font-semibold">
                      Last updated at: 19th November, 2024
                    </p>
                  </div>

                  <li className="mb-4">
                    <h3 className="text-3xl text-[#0D0D12] font-bold inline max-md:text-2xl text-left">
                      Introduction
                    </h3>
                    <p className="text-sm mt-4">
                      These Terms and Conditions (&quot;Terms&quot;) outlined on
                      this webpage constitute a legal agreement between you, as
                      a potential user of Smartcomply services/products, and
                      Smartcomply Technology Solutions Limited (referred to as
                      &quot;Smartcomply&quot;, &quot;we&quot;, &quot;our&quot;,
                      or &quot;us&quot;). These Terms govern your access to and
                      use of Smartcomply&apos;s services, which include all
                      pages on the Smartcomply website, web applications, and
                      other related products and services (collectively referred
                      to as the &quot;Services&quot;) <br />
                      By using the Services/Products, these Terms apply to you
                      in full and take effect immediately. By accessing or using
                      any of the Services, you explicitly agree to all the terms
                      and conditions stated here, without any limitations or
                      exceptions, including our Privacy Notice. If you do not
                      agree with any part of these Terms, you must not use any
                      of the Services/Products.
                    </p>
                  </li>

                  <li className="mb-4">
                    <h3 className="text-3xl text-[#0D0D12] font-bold inline max-md:text-2xl text-left">
                      Who are we?
                    </h3>
                    <p className="text-sm mt-4">
                      We are Smartcomply Technology Solutions Limited
                      (hereinafter referred to as &quot;Smartcomply&quot;). Our
                      address is The Bunker Building 279 Herbert Macaulay Way
                      Yaba, Lagos, Nigeria.
                      <br />
                      You can contact us at the above address, by email at{" "}
                      <Link
                        to="mailto:support@smartcomply.com"
                        className="font-semibold underline"
                      >
                        support@smartcomply.com
                      </Link>{" "}
                      or by telephone on{" "}
                      <span className="font-semibold"> +234 813 326 2024</span>.
                    </p>
                  </li>

                  <li className="mb-4">
                    <h3 className="text-3xl text-[#0D0D12] font-bold inline max-md:text-2xl text-left">
                      Governing Language
                    </h3>
                    <p className="text-sm mt-4">
                      English language will be the governing language of these
                      Terms and all communications between Smartcomply and you.
                    </p>
                  </li>

                  <li className="mb-4">
                    <h3 className="text-3xl text-[#0D0D12] font-bold inline max-md:text-2xl text-left">
                      Who May Use Our Services?
                    </h3>
                    <p className="text-sm mt-4">
                      You are permitted to use the Services/Products only if you
                      agree to enter into a binding contract with Smartcomply
                      and are not prohibited from receiving services under the
                      laws of the relevant jurisdiction. If you are accepting
                      these Terms and using the Services on behalf of a company,
                      business, or organization, you confirm and guarantee that
                      you have the authority to do so.
                    </p>
                  </li>

                  <li className="mb-4">
                    <h3 className="text-3xl text-[#0D0D12] font-bold inline max-md:text-2xl text-left">
                      Age Restriction
                    </h3>
                    <p className="text-sm mt-4">
                      Our website and Services/Products are intended for
                      individuals aged 18 and above. You are only allowed to use
                      the Services if you are 18 years or older. We do not
                      knowingly provide services to individuals under the age of
                      18.
                    </p>
                  </li>

                  <li className="mb-4">
                    <h3 className="text-3xl text-[#0D0D12] font-bold inline max-md:text-2xl text-left">
                      Intellectual Property
                    </h3>
                    <p className="text-sm mt-4">
                      Unless stated otherwise, Smartcomply and/or its licensors
                      own the intellectual property rights to the materials on
                      the website, subject to the license outlined below. All
                      text, formatting (including, but not limited to, the
                      arrangement of materials on the Smartcomply
                      websites/webpages), graphics, animations, tools,
                      advertisements, music, videos, articles, sound, copy,
                      trade names, logos, and other materials and information on
                      the website are protected by the intellectual property
                      rights of Smartcomply, its affiliates, licensors, and
                      licensees (collectively referred to as the
                      &quot;Content&quot;). We do not grant you any rights,
                      licenses, titles, or interests in our intellectual
                      property, whether you have access to it or not. The
                      Content may not be copied, reverse-engineered, decompiled,
                      disassembled, modified, or reposted on other websites.
                    </p>
                  </li>

                  <li className="mb-4">
                    <h3 className="text-3xl text-[#0D0D12] font-bold inline max-md:text-2xl text-left">
                      License to Use Our Website
                    </h3>
                    <p className="text-sm mt-4">
                      We grant you a limited, non-exclusive, non-transferable
                      license to access and use the Services in manners set
                      forth by the terms in this Agreement.
                    </p>
                  </li>

                  <li className="mb-4">
                    <h3 className="text-3xl text-[#0D0D12] font-bold inline max-md:text-2xl text-left">
                      Limitation of Liability
                    </h3>
                    <p className="text-sm mt-4">
                      To the fullest extent permitted by law, Smartcomply shall
                      not be liable for any indirect, incidental, special, or
                      consequential damages arising from the your use of the
                      Services/Products.
                    </p>
                  </li>

                  <li className="mb-4">
                    <h3 className="text-3xl text-[#0D0D12] font-bold inline max-md:text-2xl text-left">
                      Governing Law
                    </h3>
                    <p className="text-sm mt-4">
                      These Terms of Use and any disputes arising out of or
                      related to the use of this website or web application
                      shall be governed by and construed in accordance with the
                      laws of the Federal Republic of Nigeria, without regard to
                      its conflict of law principles. By accessing or using our
                      services/products, you agree that any legal action or
                      proceedings related to this agreement shall be subject to
                      the exclusive jurisdiction of the courts of Nigeria.
                    </p>
                  </li>

                  <li className="mb-4">
                    <h3 className="text-3xl text-[#0D0D12] font-bold inline max-md:text-2xl text-left">
                      Changes to These Terms of Use
                    </h3>
                    <p className="text-sm mt-4">
                      We reserve the right, at Our sole discretion, to modify or
                      replace these Terms at any time. If a revision is
                      material, we will provide notice to users on our “Terms of
                      Use” page. What constitutes a material change will be
                      determined at Our sole discretion.
                      <br />
                      By continuing to access or use Our Service/Product after
                      those revisions become effective, you agree to be bound by
                      the revised terms. If You do not agree to the new terms,
                      in whole or in part, please stop using the website and the
                      Service/Product.
                    </p>
                  </li>

                  <div className="mb-4">
                    <h3 className="text-3xl text-[#0D0D12] font-bold inline max-md:text-2xl text-left">
                      Contact Us
                    </h3>
                    <p className="text-sm mt-4">
                      If you have any questions or concerns about these Terms of
                      Use, you can contact us:
                    </p>
                    <ul className="list-disc list-inside text-sm">
                      <li>By telephone: +234 813 326 2024</li>
                      <li>
                        By sending us an email:{" "}
                        <Link
                          to="mailto:support@smartcomply.com"
                          className="text-primary-40 underline"
                        >
                          support@smartcomply.com
                        </Link>
                      </li>
                    </ul>
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </main>
  );
};

export default Terms;
