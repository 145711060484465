import mapbase from "assets/map/base.png";
import { useTranslation } from "react-i18next";
import { ReactComponent as CanadaIcon } from "assets/map/canada.svg";
import { ReactComponent as USIcon } from "assets/map/us.svg";
import { ReactComponent as KenyaIcon } from "assets/map/kenya.svg";
import { ReactComponent as IvoryCoastIcon } from "assets/map/ivory-coast.svg";
import { ReactComponent as GhanaIcon } from "assets/map/ghana.svg";
import { ReactComponent as NigeriaIcon } from "assets/map/nigeria.svg";
import map from "assets/map.svg";
import map_mobile from "assets/map-mobile.svg";

const Map = () => {
  const { t } = useTranslation("home");

  return (
    <section className="pt-16 pb-20 max-lg:py-14 bg-white max-md:pb-16">
      <div className="mb-14 flex items-center justify-center flex-col px-14 max-md:px-8  max-[520px]:px-4 ">
        <h3 className="text-[#0D0D12] font-bold max-md:text-[28px] w-full md:text-4xl lg:text-5xl  text-center mb-4">
          {t("section5.title")}
        </h3>
        <p className="text-[#666D80] font-[Manrope] text-center text-sm sm:w-[50%] sm:max-w-[430px]">
          {t("section5.subtitle")}
        </p>
      </div>

      <div className="w-full relative hidden lg:block px-14 max-md:px-8  max-[520px]:px-4 ">
        <img src={mapbase} alt="map" className="w-full " />
        <div className="absolute top-[5%] left-[8%] py-2 px-3 bg-[#1B2761] rounded-full h-[3.3rem] animate-pulse min-w-[fit-content] flex gap-2 items-center map-country shadow-[0px_4px_55px_5px_#12121226]">
          <CanadaIcon className="w-6 h-6" />
          <p className="text-white uppercase font-[Poppins] text-sm">Canada</p>
        </div>
        <div className="absolute top-[33%] left-[7%] py-2 px-3 bg-[#1B2761] rounded-full h-[3.3rem] animate-pulse min-w-[fit-content] flex gap-2 items-center map-country shadow-[0px_4px_55px_5px_#12121226]">
          <USIcon className="w-6 h-6" />
          <p className="text-white uppercase font-[Poppins] text-sm">USA</p>
        </div>
        <div className="absolute top-[30%] left-[40%] py-2 px-3 bg-[#1B2761] rounded-full h-[3.3rem] animate-pulse min-w-[fit-content] flex gap-2 items-center map-country shadow-[0px_4px_55px_5px_#12121226]">
          <GhanaIcon className="w-6 h-6" />
          <p className="text-white uppercase font-[Poppins] text-sm">Ghana</p>
        </div>
        <div className="absolute top-[45%] left-[36%] py-2 px-3 bg-[#1B2761] rounded-full h-[3.3rem] animate-pulse min-w-[fit-content] flex gap-2 items-center map-country shadow-[0px_4px_55px_5px_#12121226]">
          <NigeriaIcon className="w-6 h-6" />
          <p className="text-white uppercase font-[Poppins] text-sm">Nigeria</p>
        </div>
        <div className="absolute top-[41%] left-[47%] py-2 px-3 bg-[#1B2761] rounded-full h-[3.3rem] animate-pulse min-w-[fit-content] flex gap-2 items-center map-country shadow-[0px_4px_55px_5px_#12121226]">
          <IvoryCoastIcon className="w-6 h-6" />
          <p className="text-white uppercase font-[Poppins] text-sm">
            cote d'ivoire
          </p>
        </div>
        <div className="absolute top-[58%] left-[47%] py-2 px-3 bg-[#1B2761] rounded-full h-[3.3rem] animate-pulse min-w-[fit-content] flex gap-2 items-center map-country shadow-[0px_4px_55px_5px_#12121226]">
          <KenyaIcon className="w-6 h-6" />
          <p className="text-white uppercase font-[Poppins] text-sm">Kenya</p>
        </div>
      </div>

      <img src={map} alt="map" className="w-full hidden md:block lg:hidden px-14 max-md:px-8  max-[520px]:px-4 " />
      <img
        src={map_mobile}
        alt="map"
        className="w-full md:hidden max-md:block "
      />
    </section>
  );
};

export default Map;
