import aboutus from "assets/aboutus.jpg";
import { ReactComponent as ArrowHead } from "assets/arrow-head.svg";
import { ReactComponent as NDPR } from "assets/frameworks/ndpr.svg";
import { ReactComponent as PCIDSS } from "assets/frameworks/pcidss.svg";
import { ReactComponent as SOC } from "assets/frameworks/soc.svg";
import { ReactComponent as ISO } from "assets/frameworks/iso.svg";
import AnimateBottom from "components/Animations/AnimateBottom";
import { ReactComponent as LineBlue } from "assets/line-blue.svg";
import { useTranslation } from "react-i18next";

export default function AboutUs() {
  const { t } = useTranslation("about");
  const offers = [
    "Automation",
    "Artificial intelligence",
    "Real-time threat-intelligence update",
    "AAA-accredited compliance & cybersecurity courses",
    "Access to risks posed by third-party vendors",
    "Vulnerability scans",
    "Penetration tests",
    "Easy means of risk assessment where users can easily access their risks and compute risk score",
    "PCI scan on user infrastructure to scan and report vulnerabilities and cyber threats real time",
    "Recommended actions to be taken to ensure cybersecurity, background checks, cybersecurity policy training for employees, and more.",
  ];
  const alphabets = "abcdefghij";

  return (
    <>
      <div className="px-14 pb-14 py-24 max-md:px-8 max-[520px]:px-4 max-lg:py-16">
        <AnimateBottom delay={0.4}>
          <div className="flex gap-6 justify-between items-start max-md:flex-col">
            <h3 className="text-[#18214D] font-bold text-3xl max-md:text-[28px] max-w-[40%] max-md:max-w-[none]">
              {t("section1.title")}
            </h3>
            <p className="text-sm font-[Poppins] text-[#5C6077] max-w-[45%] max-md:max-w-[none]">
              {t("section1.subtitle1")}{" "}
              <span className="text-[#18214D] font-semibold">
                {t("section1.stress1")}
              </span>
              . {t("section1.subtitle2")}{" "}
              <span className="text-[#18214D] font-semibold">
                {t("section1.stress2")}
              </span>{" "}
              {t("section1.subtitle3")}
              <br />
              <br />
              {t("section1.subtitle4")}
            </p>
          </div>
        </AnimateBottom>
      </div>

      <div className="px-14 pb-14 max-md:px-8 max-[520px]:px-4 z-[10]">
        <AnimateBottom delay={0.4}>
          <div className="bg-[#0B1029] w-full rounded-lg flex flex-col justify-center items-start p-10 max-md:p-8">
            <div className="flex justify-start items-center max-md:justify-center gap-6 lg:gap-20 w-full">
              <div className="md:flex items-center justify-start w-full max-w-[50%] gap-0 flex-1 hidden">
                <div className="w-full min-w-[60px] h-[1px] bg-[#495281]" />
                <ArrowHead className="w-3 h-3 -ml-1" />
              </div>
              <div className="flex md:justify-start justify-center items-center gap-3 max-sm:flex-wrap">
                <NDPR className="w-[4.5rem] h-[4.5rem] max-[420px]:w-14 max-[420px]:h-14" />
                <PCIDSS className="w-[4.5rem] h-[4.5rem] max-[420px]:w-14 max-[420px]:h-14" />
                <SOC className="w-[4.5rem] h-[4.5rem] max-[420px]:w-14 max-[420px]:h-14" />
                <ISO className="w-[4.5rem] h-[4.5rem] max-[420px]:w-14 max-[420px]:h-14" />
              </div>
            </div>
          </div>
        </AnimateBottom>
      </div>

      <section className=" px-14 py-16 max-md:px-8 max-[520px]:px-4 max-md:pb-10 bg-transparent max-md:bg-white">
        <div className="mb-6 max-md:mb-4 flex items-start justify-start flex-col max-lg:max-w-[95%] max-[1200px]:max-w-[70%] max-w-[50%]">
          <h3 className="text-[#18214D] font-bold max-[380px]:text-3xl text-3xl max-md:text-[28px] text-left mb-4">
            {t("title")}
          </h3>
        </div>

        <img
          src={aboutus}
          alt="about us"
          className="w-full max-h-[500px] object-cover rounded-xl min-h-[250px] max-[450px]:min-h-[200px]"
        />

        <div className="pt-12 grid grid-cols-3 items-start justify-between gap-6 max-md:grid-cols-2 max-[520px]:grid-cols-1 h-full">
          <div className="flex justify-start items-start gap-3 border-r border-[#D0D4EA] pr-6 h-full max-md:border-0 max-md:px-0">
            <div>
              <h4 className="text-[#18214D] font-bold text-[15px] mb-4">
                {t("mission.title")}
              </h4>
              <p className="text-[#5C6077] font-[Poppins] text-[15px]">
                {t("mission.subtitle")}
              </p>
            </div>
          </div>
          <div className="flex justify-start items-start gap-3 border-r border-[#D0D4EA] px-6 h-full max-md:border-0 max-md:px-0">
            <div>
              <h4 className="text-[#18214D] font-bold text-[15px] mb-4">
                {t("vision.title")}
              </h4>
              <p className="text-[#5C6077] font-[Poppins] text-[15px]">
                {t("vision.subtitle")}
              </p>
            </div>
          </div>
          <div className="flex justify-start items-start gap-3 pl-6 h-full max-md:px-0">
            <div>
              <h4 className="text-[#18214D] font-bold text-[15px] mb-4">
                {t("values.title")}
              </h4>
              <p className="text-[#5C6077] font-[Poppins] text-[15px]">
                <span className="text-[#18214D] font-bold ">
                  {t("values.stress1")}:{" "}
                </span>
                {t("values.subtitle")}
                <br />
                <span className="text-[#18214D] font-bold ">
                  {t("values.stress2")}{" "}
                </span>
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className=" px-14 py-20 max-md:px-8 max-[520px]:px-4 max-lg:py-16 bg-white w-full">
        <div className="mb-6 max-md:mb-4 flex items-end justify-between w-full max-md:flex-col-reverse max-md:gap-8 max-md:justify-start max-md:items-start">
          <h3 className="text-[#18214D] font-bold max-md:text-[28px] text-3xl text-left mb-4">
            {t("offers.title")}
          </h3>
        </div>

        <div className="text-sm font-[PoppinsMedium] grid grid-cols-4 gap-2 items-center h-auto flex-wrap w-full max-md:grid-cols-2 max-[520px]:grid-cols-1 mb-2">
          {offers.slice(0, 4).map((offer, key) => (
            <div
              key={offer.slice(0, 5) + key}
              className="w-full h-full ease-in-out duration-[500ms] flex items-center justify-center max-[520px]:justify-start max-md:min-h-[60px] text-left p-3 text-[#1B2761] bg-[white] hover:bg-[#E5F0FF] border border-[#1B2761]"
            >
              <p>{t(`offers.${alphabets[key]}`)}</p>
            </div>
          ))}
        </div>
        <div
          style={{ gridTemplateColumns: "28% 20% 20% 30%" }}
          className="font-[PoppinsMedium] grid gap-2 items-center h-auto flex-wrap w-full max-lg:!grid-cols-2 max-[520px]:!grid-cols-1 max-[520px]:!grid-cols-1 mb-2"
        >
          {offers.slice(4, 8).map((offer, key) => (
            <div
              key={offer.slice(0, 5) + key}
              className={`w-full h-full ease-in-out duration-[500ms] flex items-center justify-center max-[520px]:justify-start max-md:min-h-[60px] text-left p-3 text-[#1B2761] bg-[white] hover:bg-[#E5F0FF] border border-[#1B2761]`}
            >
              <p>{t(`offers.${alphabets[key + 4]}`)}</p>
            </div>
          ))}
        </div>
        <div className="font-[PoppinsMedium] grid grid-cols-2 gap-2 items-center h-auto flex-wrap w-full max-md:grid-cols-2 max-[520px]:grid-cols-1">
          {offers.slice(8).map((offer, key) => (
            <div
              key={offer.slice(0, 5) + key}
              className="w-full h-full ease-in-out duration-[500ms] flex items-center justify-center max-[520px]:justify-start max-md:min-h-[60px] text-left p-3 text-[#1B2761] bg-[white] hover:bg-[#E5F0FF] border border-[#1B2761]"
            >
              <p>{t(`offers.${alphabets[key + 8]}`)}</p>
            </div>
          ))}
        </div>
        <div className="flex justify-start items-center gap-3 mt-8">
          <p className="font-[PoppinsSemibold] text-[#1B2761] text-base md:text-lg">
            {t(`offers.footer`)}
          </p>
          <LineBlue />
        </div>
      </section>
    </>
  );
}
